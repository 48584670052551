.ulupinar-contact {
  padding: 50px 0 0 0;
  p {
    font-weight: 600;
    font-size: 24px;
  }
  &-image {
    .image {
      max-height: 500px;
    }
    img {
      width: 1470px;
    }
  }
  &-location {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &-phone {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  &-faks {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  &-main-page {
    &-items-info {
      font-size: 24px;
      padding: 50px 0;
    }
  }
  &-input {
    background-color: light-neutral(50);
    display: flex;
    justify-content: space-between;
    padding: 50px 0;

    input {
      border-radius: 5px;
      padding-left: 20px;
      width: 90%;
      height: 50px;
      outline: none;
      border: none;
    }
    textarea {
      border-radius: 5px;
      padding: 10px;
      width: 90%;
      height: 105px;
      outline: none;
      border: none;
    }
    &-image {
      width: 50%;
    }
    &-withus {
      h2 {
        font-weight: 500;
      }
    }
  }
}
.alert-message {
  padding: 15px;
  background-color: primary(200);
  border-radius: 5px;
  color: #fff;
  opacity: 0;
  position: relative;
  top: -25px;
  transition: all, 0.4s;
  background-color: light-neutral(300);
  &--error {
    background-color: red;
  }
  &--success {
    background-color: green;
  }
  &--active {
    top: 0;
    opacity: 1;
    transition: all, 0.4s;
  }
}
.direction {
  gap: 20px;
  padding-left: 10px;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  button {
    width: 160px;
    height: 40px;
    border: none;
    background-color: #decfa6;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
  }
}
.ulupinar-input-image {
  img {
    height: 445px;
  }
}
@media (max-width: 1500px) {
}
@media (max-width: 1300px) {
  .ulupinar-contact {
    &-input {
      flex-direction: column-reverse;
    }
  }
  .ulupinar-input-image {
    img {
      height: unset;
    }
  }
  .direction {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .ulupinar-contact {
    &-location {
      display: block;
      padding-bottom: 10px;
    }
    &-image {
      padding: 0;
    }
    &-phone,
    &-faks {
      grid-gap: 10px;
      gap: 10px;
    }
    p {
      font-size: 14px;
    }
    &-main-page {
      &-items-info {
        font-size: 13px;
        padding: 20px 20px;
        p {
          font-size: 14px;
        }
      }
    }
    &-input {
      padding: 0;
      &-withus {
        h2 {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}
