.ulupinar-footer {
  background-color: #decfa6;
  width: 100%;
  &-top {
    padding: 60px 0;
  }
  .go-top {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-weight: 700;
    }
  }
  &-content {
    font-size: 20px;
    font-weight: 500;
    .contactbtn {
      border-radius: 5px;
      background-color: light-neutral(0);
      padding: 10px;
      cursor: pointer;
      display: inline-block;
      margin-top: 25px;
      font-size: 16px;
    }
  }
  &-social-media {
    font-size: 20px;
    &-title {
      text-align: right;
    }
    &-list {
      &-item {
        pointer-events: none;
      }
    }
    .c-icon-list {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  &-navigation-menu {
    color: #ffffff;
    padding: 15px 13rem;
    background-color: #2b2e34;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    ul {
      list-style-type: none;
      display: flex;
      align-items: baseline;
      gap: 2rem;
      margin: 0;
      padding: 0;
    }
  }
  &-copyright {
    text-align: right;
  }
}
.image-node {
  img {
    padding: 20px 200px;
    /* width: 78%; */
    height: 550px;
  }
}
.container-fluid {
  padding: 0;
}

@media (max-width: 1500px) {
  .ulupinar-footer {
    &-container {
      padding: 4rem 8rem;
    }
    &-navigation-menu {
      padding: 0;
      font-size: 12px;
    }
  }
}
.icon-instagram:before {
  cursor: pointer;
}
.icon-linkedin2:before {
  cursor: pointer;
}
.icon-youtube:before {
  cursor: pointer;
}
@media (max-width: 1300px) {
}
@media (max-width: 850px) {
  .ulupinar-footer {
    margin-top: 0;
    &-container {
      flex-direction: column-reverse;
      padding: 4rem 1rem;
      gap: 2rem;
    }
    &-navigation-menu {
      font-size: 8px;
    }
    &-copyright {
      text-align: left;
    }
  }
}
