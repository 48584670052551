.ulupinar-content {
  font-size: 18px;
  padding: 50px 0;
  .full-image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

@media #{$media-sm} {
  .ulupinar-content {
    font-size: 14px;
    padding: 15px 0;
    p, span {
      font-size: 14px !important;
    }
  }
}
