.UlupinarReferences {
  border-top: 1px solid;
  padding: 4rem 19rem;
  &-desc {
    font-size: 30px;
    padding: 15px 0;
  }
  &-head {
    // display: flex;
    // justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: 700;
    }
    .more {
      display: flex;
      align-items: center;
    }
  }
  &-slider {
    &-item {
      background-color: #f9f9f9;
      min-width: 250px;
      max-width: 250px;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .image {
        width: 130px;
      }
    }
  }
}

@media (max-width: 1500px) {
  .UlupinarReferences {
    padding: 4rem 8rem;
  }
}
@media (max-width: 1300px) {
  .UlupinarReferences {
    &-desc {
      font-size: 21px;
    }
  }
}
@media (max-width: 850px) {
  .UlupinarReferences {
    padding: 4rem 1rem;
  }
}
