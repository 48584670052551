@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon/icomoon.eot');
  src: url('/fonts/icomoon/icomoon.eot') format('embedded-opentype'),
    url('/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('/fonts/icomoon/icomoon.woff') format('woff'),
    url('/fonts/icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google:before {
  content: '\ee91';
}
.icon-google-plus:before {
  content: '\ee92';
}
.icon-facebook1:before {
  content: '\ee93';
}
.icon-instagram:before {
  content: '\ee94';
}
.icon-twitter:before {
  content: '\ee95';
}
.icon-youtube:before {
  content: '\ee96';
}
.icon-linkedin2:before {
  content: '\ee97';
}
.icon-whatsapp:before {
  content: '\ea93';
}
.icon-error:before {
  content: '\e900';
}
.icon-error-outline:before {
  content: '\e901';
}
.icon-warning:before {
  content: '\e902';
}
.icon-add-alert:before {
  content: '\e903';
}
.icon-notification-important:before {
  content: '\e904';
}
.icon-album:before {
  content: '\e905';
}
.icon-av-timer:before {
  content: '\e906';
}
.icon-closed-caption:before {
  content: '\e907';
}
.icon-equalizer:before {
  content: '\e908';
}
.icon-explicit:before {
  content: '\e909';
}
.icon-fast-forward:before {
  content: '\e90a';
}
.icon-fast-rewind:before {
  content: '\e90b';
}
.icon-games:before {
  content: '\e90c';
}
.icon-hearing:before {
  content: '\e90d';
}
.icon-high-quality:before {
  content: '\e90e';
}
.icon-loop:before {
  content: '\e90f';
}
.icon-mic:before {
  content: '\e910';
}
.icon-mic-none:before {
  content: '\e911';
}
.icon-mic-off:before {
  content: '\e912';
}
.icon-movie:before {
  content: '\e913';
}
.icon-library-add:before {
  content: '\e914';
}
.icon-library-books:before {
  content: '\e915';
}
.icon-library-music:before {
  content: '\e916';
}
.icon-new-releases:before {
  content: '\e917';
}
.icon-not-interested:before {
  content: '\e918';
}
.icon-pause:before {
  content: '\e919';
}
.icon-pause-circle-filled:before {
  content: '\e91a';
}
.icon-pause-circle-outline:before {
  content: '\e91b';
}
.icon-play-arrow:before {
  content: '\e91c';
}
.icon-play-circle-filled:before {
  content: '\e91d';
}
.icon-play-circle-outline:before {
  content: '\e91e';
}
.icon-playlist-add:before {
  content: '\e91f';
}
.icon-queue-music:before {
  content: '\e920';
}
.icon-radio:before {
  content: '\e921';
}
.icon-recent-actors:before {
  content: '\e922';
}
.icon-repeat:before {
  content: '\e923';
}
.icon-repeat-one:before {
  content: '\e924';
}
.icon-replay:before {
  content: '\e925';
}
.icon-shuffle:before {
  content: '\e926';
}
.icon-skip-next:before {
  content: '\e927';
}
.icon-skip-previous:before {
  content: '\e928';
}
.icon-snooze:before {
  content: '\e929';
}
.icon-stop:before {
  content: '\e92a';
}
.icon-subtitles:before {
  content: '\e92b';
}
.icon-surround-sound:before {
  content: '\e92c';
}
.icon-video-library:before {
  content: '\e92d';
}
.icon-videocam:before {
  content: '\e92e';
}
.icon-videocam-off:before {
  content: '\e92f';
}
.icon-volume-down:before {
  content: '\e930';
}
.icon-volume-mute:before {
  content: '\e931';
}
.icon-volume-off:before {
  content: '\e932';
}
.icon-volume-up:before {
  content: '\e933';
}
.icon-web:before {
  content: '\e934';
}
.icon-hd:before {
  content: '\e935';
}
.icon-sort-by-alpha:before {
  content: '\e936';
}
.icon-airplay:before {
  content: '\e937';
}
.icon-forward-10:before {
  content: '\e938';
}
.icon-forward-30:before {
  content: '\e939';
}
.icon-forward-5:before {
  content: '\e93a';
}
.icon-replay-10:before {
  content: '\e93b';
}
.icon-replay-30:before {
  content: '\e93c';
}
.icon-replay-5:before {
  content: '\e93d';
}
.icon-add-to-queue:before {
  content: '\e93e';
}
.icon-fiber-dvr:before {
  content: '\e93f';
}
.icon-fiber-new:before {
  content: '\e940';
}
.icon-playlist-play:before {
  content: '\e941';
}
.icon-art-track:before {
  content: '\e942';
}
.icon-fiber-manual-record:before {
  content: '\e943';
}
.icon-fiber-smart-record:before {
  content: '\e944';
}
.icon-music-video:before {
  content: '\e945';
}
.icon-subscriptions:before {
  content: '\e946';
}
.icon-playlist-add-check:before {
  content: '\e947';
}
.icon-queue-play-next:before {
  content: '\e948';
}
.icon-remove-from-queue:before {
  content: '\e949';
}
.icon-slow-motion-video:before {
  content: '\e94a';
}
.icon-web-asset:before {
  content: '\e94b';
}
.icon-fiber-pin:before {
  content: '\e94c';
}
.icon-branding-watermark:before {
  content: '\e94d';
}
.icon-call-to-action:before {
  content: '\e94e';
}
.icon-featured-play-list:before {
  content: '\e94f';
}
.icon-featured-video:before {
  content: '\e950';
}
.icon-note:before {
  content: '\e951';
}
.icon-video-call:before {
  content: '\e952';
}
.icon-video-label:before {
  content: '\e953';
}
.icon-4k:before {
  content: '\e954';
}
.icon-missed-video-call:before {
  content: '\e955';
}
.icon-control-camera:before {
  content: '\e956';
}
.icon-business:before {
  content: '\e957';
}
.icon-call:before {
  content: '\e958';
}
.icon-call-end:before {
  content: '\e959';
}
.icon-call-made:before {
  content: '\e95a';
}
.icon-call-merge:before {
  content: '\e95b';
}
.icon-call-missed:before {
  content: '\e95c';
}
.icon-call-received:before {
  content: '\e95d';
}
.icon-call-split:before {
  content: '\e95e';
}
.icon-chat:before {
  content: '\e95f';
}
.icon-clear-all:before {
  content: '\e960';
}
.icon-comment:before {
  content: '\e961';
}
.icon-contacts:before {
  content: '\e962';
}
.icon-dialer-sip:before {
  content: '\e963';
}
.icon-dialpad:before {
  content: '\e964';
}
.icon-email:before {
  content: '\e965';
}
.icon-forum:before {
  content: '\e966';
}
.icon-import-export:before {
  content: '\e967';
}
.icon-invert-colors-off:before {
  content: '\e968';
}
.icon-live-help:before {
  content: '\e969';
}
.icon-location-off:before {
  content: '\e96a';
}
.icon-location-on:before {
  content: '\e96b';
}
.icon-message:before {
  content: '\e96c';
}
.icon-chat-bubble:before {
  content: '\e96d';
}
.icon-chat-bubble-outline:before {
  content: '\e96e';
}
.icon-no-sim:before {
  content: '\e96f';
}
.icon-phone:before {
  content: '\e970';
}
.icon-portable-wifi-off:before {
  content: '\e971';
}
.icon-contact-phone:before {
  content: '\e972';
}
.icon-contact-mail:before {
  content: '\e973';
}
.icon-ring-volume:before {
  content: '\e974';
}
.icon-speaker-phone:before {
  content: '\e975';
}
.icon-stay-current-landscape:before {
  content: '\e976';
}
.icon-stay-current-portrait:before {
  content: '\e977';
}
.icon-swap-calls:before {
  content: '\e978';
}
.icon-textsms:before {
  content: '\e979';
}
.icon-voicemail:before {
  content: '\e97a';
}
.icon-vpn-key:before {
  content: '\e97b';
}
.icon-phonelink-erase:before {
  content: '\e97c';
}
.icon-phonelink-lock:before {
  content: '\e97d';
}
.icon-phonelink-ring:before {
  content: '\e97e';
}
.icon-phonelink-setup:before {
  content: '\e97f';
}
.icon-present-to-all:before {
  content: '\e980';
}
.icon-import-contacts:before {
  content: '\e981';
}
.icon-mail-outline:before {
  content: '\e982';
}
.icon-screen-share:before {
  content: '\e983';
}
.icon-stop-screen-share:before {
  content: '\e984';
}
.icon-call-missed-outgoing:before {
  content: '\e985';
}
.icon-rss-feed:before {
  content: '\e986';
}
.icon-alternate-email:before {
  content: '\e987';
}
.icon-mobile-screen-share:before {
  content: '\e988';
}
.icon-add-call:before {
  content: '\e989';
}
.icon-cancel-presentation:before {
  content: '\e98a';
}
.icon-pause-presentation:before {
  content: '\e98b';
}
.icon-unsubscribe:before {
  content: '\e98c';
}
.icon-cell-wifi:before {
  content: '\e98d';
}
.icon-sentiment-satisfied-alt:before {
  content: '\e98e';
}
.icon-list-alt:before {
  content: '\e98f';
}
.icon-domain-disabled:before {
  content: '\e990';
}
.icon-lightbulb:before {
  content: '\e991';
}
.icon-add:before {
  content: '\e992';
}
.icon-add-box:before {
  content: '\e993';
}
.icon-add-circle:before {
  content: '\e994';
}
.icon-add-circle-outline:before {
  content: '\e995';
}
.icon-archive:before {
  content: '\e996';
}
.icon-backspace:before {
  content: '\e997';
}
.icon-block:before {
  content: '\e998';
}
.icon-clear:before {
  content: '\e999';
}
.icon-content-copy:before {
  content: '\e99a';
}
.icon-content-cut:before {
  content: '\e99b';
}
.icon-content-paste:before {
  content: '\e99c';
}
.icon-create:before {
  content: '\e99d';
}
.icon-drafts:before {
  content: '\e99e';
}
.icon-filter-list:before {
  content: '\e99f';
}
.icon-flag:before {
  content: '\e9a0';
}
.icon-forward:before {
  content: '\e9a1';
}
.icon-gesture:before {
  content: '\e9a2';
}
.icon-inbox:before {
  content: '\e9a3';
}
.icon-link:before {
  content: '\e9a4';
}
.icon-redo:before {
  content: '\e9a5';
}
.icon-remove:before {
  content: '\e9a6';
}
.icon-remove-circle:before {
  content: '\e9a7';
}
.icon-remove-circle-outline:before {
  content: '\e9a8';
}
.icon-reply:before {
  content: '\e9a9';
}
.icon-reply-all:before {
  content: '\e9aa';
}
.icon-report:before {
  content: '\e9ab';
}
.icon-save:before {
  content: '\e9ac';
}
.icon-select-all:before {
  content: '\e9ad';
}
.icon-send:before {
  content: '\e9ae';
}
.icon-sort:before {
  content: '\e9af';
}
.icon-text-format:before {
  content: '\e9b0';
}
.icon-undo:before {
  content: '\e9b1';
}
.icon-font-download:before {
  content: '\e9b2';
}
.icon-move-to-inbox:before {
  content: '\e9b3';
}
.icon-unarchive:before {
  content: '\e9b4';
}
.icon-next-week:before {
  content: '\e9b5';
}
.icon-weekend:before {
  content: '\e9b6';
}
.icon-delete-sweep:before {
  content: '\e9b7';
}
.icon-low-priority:before {
  content: '\e9b8';
}
.icon-outlined-flag:before {
  content: '\e9b9';
}
.icon-link-off:before {
  content: '\e9ba';
}
.icon-report-off:before {
  content: '\e9bb';
}
.icon-save-alt:before {
  content: '\e9bc';
}
.icon-ballot:before {
  content: '\e9bd';
}
.icon-file-copy:before {
  content: '\e9be';
}
.icon-how-to-reg:before {
  content: '\e9bf';
}
.icon-how-to-vote:before {
  content: '\e9c0';
}
.icon-waves:before {
  content: '\e9c1';
}
.icon-where-to-vote:before {
  content: '\e9c2';
}
.icon-add-link:before {
  content: '\e9c3';
}
.icon-inventory:before {
  content: '\e9c4';
}
.icon-access-alarm:before {
  content: '\e9c5';
}
.icon-access-alarms:before {
  content: '\e9c6';
}
.icon-access-time:before {
  content: '\e9c7';
}
.icon-add-alarm:before {
  content: '\e9c8';
}
.icon-airplanemode-off:before {
  content: '\e9c9';
}
.icon-airplanemode-on:before {
  content: '\e9ca';
}
.icon-battery-alert:before {
  content: '\e9cb';
}
.icon-battery-charging-full:before {
  content: '\e9cc';
}
.icon-battery-full:before {
  content: '\e9cd';
}
.icon-battery-unknown:before {
  content: '\e9ce';
}
.icon-bluetooth:before {
  content: '\e9cf';
}
.icon-bluetooth-connected:before {
  content: '\e9d0';
}
.icon-bluetooth-disabled:before {
  content: '\e9d1';
}
.icon-bluetooth-searching:before {
  content: '\e9d2';
}
.icon-brightness-auto:before {
  content: '\e9d3';
}
.icon-brightness-high:before {
  content: '\e9d4';
}
.icon-brightness-low:before {
  content: '\e9d5';
}
.icon-brightness-medium:before {
  content: '\e9d6';
}
.icon-data-usage:before {
  content: '\e9d7';
}
.icon-developer-mode:before {
  content: '\e9d8';
}
.icon-devices:before {
  content: '\e9d9';
}
.icon-dvr:before {
  content: '\e9da';
}
.icon-gps-fixed:before {
  content: '\e9db';
}
.icon-gps-not-fixed:before {
  content: '\e9dc';
}
.icon-gps-off:before {
  content: '\e9dd';
}
.icon-graphic-eq:before {
  content: '\e9de';
}
.icon-network-cell:before {
  content: '\e9df';
}
.icon-network-wifi:before {
  content: '\e9e0';
}
.icon-nfc:before {
  content: '\e9e1';
}
.icon-now-wallpaper:before {
  content: '\e9e2';
}
.icon-now-widgets:before {
  content: '\e9e3';
}
.icon-screen-lock-landscape:before {
  content: '\e9e4';
}
.icon-screen-lock-portrait:before {
  content: '\e9e5';
}
.icon-screen-lock-rotation:before {
  content: '\e9e6';
}
.icon-screen-rotation:before {
  content: '\e9e7';
}
.icon-sd-storage:before {
  content: '\e9e8';
}
.icon-settings-system-daydream:before {
  content: '\e9e9';
}
.icon-signal-cellular-4-bar:before {
  content: '\e9ea';
}
.icon-signal-cellular-connected-no-internet-4-bar:before {
  content: '\e9eb';
}
.icon-signal-cellular-null:before {
  content: '\e9ec';
}
.icon-signal-cellular-off:before {
  content: '\e9ed';
}
.icon-signal-wifi-4-bar:before {
  content: '\e9ee';
}
.icon-signal-wifi-4-bar-lock:before {
  content: '\e9ef';
}
.icon-signal-wifi-off:before {
  content: '\e9f0';
}
.icon-storage:before {
  content: '\e9f1';
}
.icon-usb:before {
  content: '\e9f2';
}
.icon-wifi-lock:before {
  content: '\e9f3';
}
.icon-wifi-tethering:before {
  content: '\e9f4';
}
.icon-add-to-home-screen:before {
  content: '\e9f5';
}
.icon-device-thermostat:before {
  content: '\e9f6';
}
.icon-mobile-friendly:before {
  content: '\e9f7';
}
.icon-mobile-off:before {
  content: '\e9f8';
}
.icon-signal-cellular-alt:before {
  content: '\e9f9';
}
.icon-attach-file:before {
  content: '\e9fa';
}
.icon-attach-money:before {
  content: '\e9fb';
}
.icon-border-all:before {
  content: '\e9fc';
}
.icon-border-bottom:before {
  content: '\e9fd';
}
.icon-border-clear:before {
  content: '\e9fe';
}
.icon-border-color:before {
  content: '\e9ff';
}
.icon-border-horizontal:before {
  content: '\ea00';
}
.icon-border-inner:before {
  content: '\ea01';
}
.icon-border-left:before {
  content: '\ea02';
}
.icon-border-outer:before {
  content: '\ea03';
}
.icon-border-right:before {
  content: '\ea04';
}
.icon-border-style:before {
  content: '\ea05';
}
.icon-border-top:before {
  content: '\ea06';
}
.icon-border-vertical:before {
  content: '\ea07';
}
.icon-format-align-center:before {
  content: '\ea08';
}
.icon-format-align-justify:before {
  content: '\ea09';
}
.icon-format-align-left:before {
  content: '\ea0a';
}
.icon-format-align-right:before {
  content: '\ea0b';
}
.icon-format-bold:before {
  content: '\ea0c';
}
.icon-format-clear:before {
  content: '\ea0d';
}
.icon-format-color-fill:before {
  content: '\ea0e';
}
.icon-format-color-reset:before {
  content: '\ea0f';
}
.icon-format-color-text:before {
  content: '\ea10';
}
.icon-format-indent-decrease:before {
  content: '\ea11';
}
.icon-format-indent-increase:before {
  content: '\ea12';
}
.icon-format-italic:before {
  content: '\ea13';
}
.icon-format-line-spacing:before {
  content: '\ea14';
}
.icon-format-list-bulleted:before {
  content: '\ea15';
}
.icon-format-list-numbered:before {
  content: '\ea16';
}
.icon-format-paint:before {
  content: '\ea17';
}
.icon-format-quote:before {
  content: '\ea18';
}
.icon-format-size:before {
  content: '\ea19';
}
.icon-format-strikethrough:before {
  content: '\ea1a';
}
.icon-format-textdirection-l-to-r:before {
  content: '\ea1b';
}
.icon-format-textdirection-r-to-l:before {
  content: '\ea1c';
}
.icon-format-underlined:before {
  content: '\ea1d';
}
.icon-functions:before {
  content: '\ea1e';
}
.icon-insert-chart:before {
  content: '\ea1f';
}
.icon-insert-comment:before {
  content: '\ea20';
}
.icon-insert-drive-file:before {
  content: '\ea21';
}
.icon-insert-emoticon:before {
  content: '\ea22';
}
.icon-insert-invitation:before {
  content: '\ea23';
}
.icon-insert-photo:before {
  content: '\ea24';
}
.icon-mode-comment:before {
  content: '\ea25';
}
.icon-publish:before {
  content: '\ea26';
}
.icon-space-bar:before {
  content: '\ea27';
}
.icon-strikethrough-s:before {
  content: '\ea28';
}
.icon-vertical-align-bottom:before {
  content: '\ea29';
}
.icon-vertical-align-center:before {
  content: '\ea2a';
}
.icon-vertical-align-top:before {
  content: '\ea2b';
}
.icon-wrap-text:before {
  content: '\ea2c';
}
.icon-money-off:before {
  content: '\ea2d';
}
.icon-drag-handle:before {
  content: '\ea2e';
}
.icon-format-shapes:before {
  content: '\ea2f';
}
.icon-highlight:before {
  content: '\ea30';
}
.icon-linear-scale:before {
  content: '\ea31';
}
.icon-short-text:before {
  content: '\ea32';
}
.icon-text-fields:before {
  content: '\ea33';
}
.icon-monetization-on:before {
  content: '\ea34';
}
.icon-title:before {
  content: '\ea35';
}
.icon-table-chart:before {
  content: '\ea36';
}
.icon-add-comment:before {
  content: '\ea37';
}
.icon-format-list-numbered-rtl:before {
  content: '\ea38';
}
.icon-scatter-plot:before {
  content: '\ea39';
}
.icon-score:before {
  content: '\ea3a';
}
.icon-insert-chart-outlined:before {
  content: '\ea3b';
}
.icon-bar-chart:before {
  content: '\ea3c';
}
.icon-notes:before {
  content: '\ea3d';
}
.icon-attachment:before {
  content: '\ea3e';
}
.icon-cloud:before {
  content: '\ea3f';
}
.icon-cloud-circle:before {
  content: '\ea40';
}
.icon-cloud-done:before {
  content: '\ea41';
}
.icon-cloud-download:before {
  content: '\ea42';
}
.icon-cloud-off:before {
  content: '\ea43';
}
.icon-cloud-queue:before {
  content: '\ea44';
}
.icon-cloud-upload:before {
  content: '\ea45';
}
.icon-file-download:before {
  content: '\ea46';
}
.icon-file-upload:before {
  content: '\ea47';
}
.icon-folder:before {
  content: '\ea48';
}
.icon-folder-open:before {
  content: '\ea49';
}
.icon-folder-shared:before {
  content: '\ea4a';
}
.icon-create-new-folder:before {
  content: '\ea4b';
}
.icon-cast:before {
  content: '\ea4c';
}
.icon-cast-connected:before {
  content: '\ea4d';
}
.icon-computer:before {
  content: '\ea4e';
}
.icon-desktop-mac:before {
  content: '\ea4f';
}
.icon-desktop-windows:before {
  content: '\ea50';
}
.icon-developer-board:before {
  content: '\ea51';
}
.icon-dock:before {
  content: '\ea52';
}
.icon-headset:before {
  content: '\ea53';
}
.icon-headset-mic:before {
  content: '\ea54';
}
.icon-keyboard:before {
  content: '\ea55';
}
.icon-keyboard-arrow-down:before {
  content: '\ea56';
}
.icon-keyboard-arrow-left:before {
  content: '\ea57';
}
.icon-keyboard-arrow-right:before {
  content: '\ea58';
}
.icon-keyboard-arrow-up:before {
  content: '\ea59';
}
.icon-keyboard-backspace:before {
  content: '\ea5a';
}
.icon-keyboard-capslock:before {
  content: '\ea5b';
}
.icon-keyboard-hide:before {
  content: '\ea5c';
}
.icon-keyboard-return:before {
  content: '\ea5d';
}
.icon-keyboard-tab:before {
  content: '\ea5e';
}
.icon-keyboard-voice:before {
  content: '\ea5f';
}
.icon-laptop-chromebook:before {
  content: '\ea60';
}
.icon-laptop-mac:before {
  content: '\ea61';
}
.icon-laptop-windows:before {
  content: '\ea62';
}
.icon-memory:before {
  content: '\ea63';
}
.icon-mouse:before {
  content: '\ea64';
}
.icon-phone-android:before {
  content: '\ea65';
}
.icon-phone-iphone:before {
  content: '\ea66';
}
.icon-phonelink-off:before {
  content: '\ea67';
}
.icon-router:before {
  content: '\ea68';
}
.icon-scanner:before {
  content: '\ea69';
}
.icon-security:before {
  content: '\ea6a';
}
.icon-sim-card:before {
  content: '\ea6b';
}
.icon-speaker:before {
  content: '\ea6c';
}
.icon-speaker-group:before {
  content: '\ea6d';
}
.icon-tablet:before {
  content: '\ea6e';
}
.icon-tablet-android:before {
  content: '\ea6f';
}
.icon-tablet-mac:before {
  content: '\ea70';
}
.icon-toys:before {
  content: '\ea71';
}
.icon-tv:before {
  content: '\ea72';
}
.icon-watch:before {
  content: '\ea73';
}
.icon-device-hub:before {
  content: '\ea74';
}
.icon-power-input:before {
  content: '\ea75';
}
.icon-devices-other:before {
  content: '\ea76';
}
.icon-videogame-asset:before {
  content: '\ea77';
}
.icon-device-unknown:before {
  content: '\ea78';
}
.icon-headset-off:before {
  content: '\ea79';
}
.icon-adjust:before {
  content: '\ea7a';
}
.icon-assistant:before {
  content: '\ea7b';
}
.icon-audiotrack:before {
  content: '\ea7c';
}
.icon-blur-circular:before {
  content: '\ea7d';
}
.icon-blur-linear:before {
  content: '\ea7e';
}
.icon-blur-off:before {
  content: '\ea7f';
}
.icon-blur-on:before {
  content: '\ea80';
}
.icon-brightness-1:before {
  content: '\ea81';
}
.icon-brightness-2:before {
  content: '\ea82';
}
.icon-brightness-3:before {
  content: '\ea83';
}
.icon-brightness-4:before {
  content: '\ea84';
}
.icon-broken-image:before {
  content: '\ea85';
}
.icon-brush:before {
  content: '\ea86';
}
.icon-camera:before {
  content: '\ea87';
}
.icon-camera-alt:before {
  content: '\ea88';
}
.icon-camera-front:before {
  content: '\ea89';
}
.icon-camera-rear:before {
  content: '\ea8a';
}
.icon-camera-roll:before {
  content: '\ea8b';
}
.icon-center-focus-strong:before {
  content: '\ea8c';
}
.icon-center-focus-weak:before {
  content: '\ea8d';
}
.icon-collections:before {
  content: '\ea8e';
}
.icon-color-lens:before {
  content: '\ea8f';
}
.icon-colorize:before {
  content: '\ea90';
}
.icon-compare:before {
  content: '\ea91';
}
.icon-control-point-duplicate:before {
  content: '\ea92';
}
.icon-crop-16-9:before {
  content: '\ea94';
}
.icon-crop-3-2:before {
  content: '\ea95';
}
.icon-crop:before {
  content: '\ea96';
}
.icon-crop-5-4:before {
  content: '\ea97';
}
.icon-crop-7-5:before {
  content: '\ea98';
}
.icon-crop-din:before {
  content: '\ea99';
}
.icon-crop-free:before {
  content: '\ea9a';
}
.icon-crop-original:before {
  content: '\ea9b';
}
.icon-crop-portrait:before {
  content: '\ea9c';
}
.icon-crop-square:before {
  content: '\ea9d';
}
.icon-dehaze:before {
  content: '\ea9e';
}
.icon-details:before {
  content: '\ea9f';
}
.icon-exposure:before {
  content: '\eaa0';
}
.icon-exposure-neg-1:before {
  content: '\eaa1';
}
.icon-exposure-neg-2:before {
  content: '\eaa2';
}
.icon-exposure-plus-1:before {
  content: '\eaa3';
}
.icon-exposure-plus-2:before {
  content: '\eaa4';
}
.icon-exposure-zero:before {
  content: '\eaa5';
}
.icon-filter-1:before {
  content: '\eaa6';
}
.icon-filter-2:before {
  content: '\eaa7';
}
.icon-filter-3:before {
  content: '\eaa8';
}
.icon-filter:before {
  content: '\eaa9';
}
.icon-filter-4:before {
  content: '\eaaa';
}
.icon-filter-5:before {
  content: '\eaab';
}
.icon-filter-6:before {
  content: '\eaac';
}
.icon-filter-7:before {
  content: '\eaad';
}
.icon-filter-8:before {
  content: '\eaae';
}
.icon-filter-9:before {
  content: '\eaaf';
}
.icon-filter-9-plus:before {
  content: '\eab0';
}
.icon-filter-b-and-w:before {
  content: '\eab1';
}
.icon-filter-center-focus:before {
  content: '\eab2';
}
.icon-filter-drama:before {
  content: '\eab3';
}
.icon-filter-frames:before {
  content: '\eab4';
}
.icon-filter-hdr:before {
  content: '\eab5';
}
.icon-filter-none:before {
  content: '\eab6';
}
.icon-filter-tilt-shift:before {
  content: '\eab7';
}
.icon-filter-vintage:before {
  content: '\eab8';
}
.icon-flare:before {
  content: '\eab9';
}
.icon-flash-auto:before {
  content: '\eaba';
}
.icon-flash-off:before {
  content: '\eabb';
}
.icon-flash-on:before {
  content: '\eabc';
}
.icon-flip:before {
  content: '\eabd';
}
.icon-gradient:before {
  content: '\eabe';
}
.icon-grain:before {
  content: '\eabf';
}
.icon-grid-off:before {
  content: '\eac0';
}
.icon-grid-on:before {
  content: '\eac1';
}
.icon-hdr-off:before {
  content: '\eac2';
}
.icon-hdr-on:before {
  content: '\eac3';
}
.icon-hdr-strong:before {
  content: '\eac4';
}
.icon-hdr-weak:before {
  content: '\eac5';
}
.icon-healing:before {
  content: '\eac6';
}
.icon-image-aspect-ratio:before {
  content: '\eac7';
}
.icon-iso:before {
  content: '\eac8';
}
.icon-leak-add:before {
  content: '\eac9';
}
.icon-leak-remove:before {
  content: '\eaca';
}
.icon-lens:before {
  content: '\eacb';
}
.icon-looks-3:before {
  content: '\eacc';
}
.icon-looks:before {
  content: '\eacd';
}
.icon-looks-4:before {
  content: '\eace';
}
.icon-looks-5:before {
  content: '\eacf';
}
.icon-looks-6:before {
  content: '\ead0';
}
.icon-looks-one:before {
  content: '\ead1';
}
.icon-looks-two:before {
  content: '\ead2';
}
.icon-loupe:before {
  content: '\ead3';
}
.icon-monochrome-photos:before {
  content: '\ead4';
}
.icon-music-note:before {
  content: '\ead5';
}
.icon-nature:before {
  content: '\ead6';
}
.icon-nature-people:before {
  content: '\ead7';
}
.icon-navigate-before:before {
  content: '\ead8';
}
.icon-navigate-next:before {
  content: '\ead9';
}
.icon-panorama:before {
  content: '\eada';
}
.icon-panorama-fisheye:before {
  content: '\eadb';
}
.icon-panorama-horizontal:before {
  content: '\eadc';
}
.icon-panorama-vertical:before {
  content: '\eadd';
}
.icon-panorama-wide-angle:before {
  content: '\eade';
}
.icon-photo-album:before {
  content: '\eadf';
}
.icon-picture-as-pdf:before {
  content: '\eae0';
}
.icon-portrait:before {
  content: '\eae1';
}
.icon-remove-red-eye:before {
  content: '\eae2';
}
.icon-rotate-90-degrees-ccw:before {
  content: '\eae3';
}
.icon-rotate-left:before {
  content: '\eae4';
}
.icon-rotate-right:before {
  content: '\eae5';
}
.icon-slideshow:before {
  content: '\eae6';
}
.icon-straighten:before {
  content: '\eae7';
}
.icon-style:before {
  content: '\eae8';
}
.icon-switch-camera:before {
  content: '\eae9';
}
.icon-switch-video:before {
  content: '\eaea';
}
.icon-texture:before {
  content: '\eaeb';
}
.icon-timelapse:before {
  content: '\eaec';
}
.icon-timer-10:before {
  content: '\eaed';
}
.icon-timer-3:before {
  content: '\eaee';
}
.icon-timer:before {
  content: '\eaef';
}
.icon-timer-off:before {
  content: '\eaf0';
}
.icon-tonality:before {
  content: '\eaf1';
}
.icon-transform:before {
  content: '\eaf2';
}
.icon-tune:before {
  content: '\eaf3';
}
.icon-view-comfy:before {
  content: '\eaf4';
}
.icon-view-compact:before {
  content: '\eaf5';
}
.icon-wb-auto:before {
  content: '\eaf6';
}
.icon-wb-cloudy:before {
  content: '\eaf7';
}
.icon-wb-incandescent:before {
  content: '\eaf8';
}
.icon-wb-sunny:before {
  content: '\eaf9';
}
.icon-collections-bookmark:before {
  content: '\eafa';
}
.icon-photo-size-select-actual:before {
  content: '\eafb';
}
.icon-photo-size-select-large:before {
  content: '\eafc';
}
.icon-photo-size-select-small:before {
  content: '\eafd';
}
.icon-vignette:before {
  content: '\eafe';
}
.icon-wb-iridescent:before {
  content: '\eaff';
}
.icon-crop-rotate:before {
  content: '\eb00';
}
.icon-linked-camera:before {
  content: '\eb01';
}
.icon-add-a-photo:before {
  content: '\eb02';
}
.icon-movie-filter:before {
  content: '\eb03';
}
.icon-photo-filter:before {
  content: '\eb04';
}
.icon-burst-mode:before {
  content: '\eb05';
}
.icon-shutter-speed:before {
  content: '\eb06';
}
.icon-add-photo-alternate:before {
  content: '\eb07';
}
.icon-image-search:before {
  content: '\eb08';
}
.icon-music-off:before {
  content: '\eb09';
}
.icon-beenhere:before {
  content: '\eb0a';
}
.icon-directions:before {
  content: '\eb0b';
}
.icon-directions-bike:before {
  content: '\eb0c';
}
.icon-directions-bus:before {
  content: '\eb0d';
}
.icon-directions-car:before {
  content: '\eb0e';
}
.icon-directions-ferry:before {
  content: '\eb0f';
}
.icon-directions-subway:before {
  content: '\eb10';
}
.icon-directions-train:before {
  content: '\eb11';
}
.icon-directions-walk:before {
  content: '\eb12';
}
.icon-hotel:before {
  content: '\eb13';
}
.icon-layers:before {
  content: '\eb14';
}
.icon-layers-clear:before {
  content: '\eb15';
}
.icon-local-atm:before {
  content: '\eb16';
}
.icon-local-attraction:before {
  content: '\eb17';
}
.icon-local-bar:before {
  content: '\eb18';
}
.icon-local-cafe:before {
  content: '\eb19';
}
.icon-local-car-wash:before {
  content: '\eb1a';
}
.icon-local-convenience-store:before {
  content: '\eb1b';
}
.icon-local-drink:before {
  content: '\eb1c';
}
.icon-local-florist:before {
  content: '\eb1d';
}
.icon-local-gas-station:before {
  content: '\eb1e';
}
.icon-local-grocery-store:before {
  content: '\eb1f';
}
.icon-local-hospital:before {
  content: '\eb20';
}
.icon-local-laundry-service:before {
  content: '\eb21';
}
.icon-local-library:before {
  content: '\eb22';
}
.icon-local-mall:before {
  content: '\eb23';
}
.icon-local-movies:before {
  content: '\eb24';
}
.icon-local-offer:before {
  content: '\eb25';
}
.icon-local-parking:before {
  content: '\eb26';
}
.icon-local-pharmacy:before {
  content: '\eb27';
}
.icon-local-pizza:before {
  content: '\eb28';
}
.icon-local-printshop:before {
  content: '\eb29';
}
.icon-local-restaurant:before {
  content: '\eb2a';
}
.icon-local-shipping:before {
  content: '\eb2b';
}
.icon-local-taxi:before {
  content: '\eb2c';
}
.icon-location-history:before {
  content: '\eb2d';
}
.icon-map:before {
  content: '\eb2e';
}
.icon-navigation:before {
  content: '\eb2f';
}
.icon-pin-drop:before {
  content: '\eb30';
}
.icon-rate-review:before {
  content: '\eb31';
}
.icon-satellite:before {
  content: '\eb32';
}
.icon-store-mall-directory:before {
  content: '\eb33';
}
.icon-traffic:before {
  content: '\eb34';
}
.icon-directions-run:before {
  content: '\eb35';
}
.icon-add-location:before {
  content: '\eb36';
}
.icon-edit-location:before {
  content: '\eb37';
}
.icon-near-me:before {
  content: '\eb38';
}
.icon-person-pin-circle:before {
  content: '\eb39';
}
.icon-zoom-out-map:before {
  content: '\eb3a';
}
.icon-restaurant:before {
  content: '\eb3b';
}
.icon-ev-station:before {
  content: '\eb3c';
}
.icon-streetview:before {
  content: '\eb3d';
}
.icon-subway:before {
  content: '\eb3e';
}
.icon-train:before {
  content: '\eb3f';
}
.icon-tram:before {
  content: '\eb40';
}
.icon-transfer-within-a-station:before {
  content: '\eb41';
}
.icon-atm:before {
  content: '\eb42';
}
.icon-category:before {
  content: '\eb43';
}
.icon-not-listed-location:before {
  content: '\eb44';
}
.icon-departure-board:before {
  content: '\eb45';
}
.icon-360:before {
  content: '\eb46';
}
.icon-edit-attributes:before {
  content: '\eb47';
}
.icon-transit-enterexit:before {
  content: '\eb48';
}
.icon-fastfood:before {
  content: '\eb49';
}
.icon-trip-origin:before {
  content: '\eb4a';
}
.icon-compass-calibration:before {
  content: '\eb4b';
}
.icon-money:before {
  content: '\eb4c';
}
.icon-apps:before {
  content: '\eb4d';
}
.icon-arrow-back:before {
  content: '\eb4e';
}
.icon-arrow-drop-down:before {
  content: '\eb4f';
}
.icon-arrow-drop-down-circle:before {
  content: '\eb50';
}
.icon-arrow-drop-up:before {
  content: '\eb51';
}
.icon-arrow-forward:before {
  content: '\eb52';
}
.icon-cancel:before {
  content: '\eb53';
}
.icon-check:before {
  content: '\eb54';
}
.icon-expand-less:before {
  content: '\eb55';
}
.icon-expand-more:before {
  content: '\eb56';
}
.icon-fullscreen:before {
  content: '\eb57';
}
.icon-fullscreen-exit:before {
  content: '\eb58';
}
.icon-menu:before {
  content: '\eb59';
}
.icon-keyboard-control:before {
  content: '\eb5a';
}
.icon-more-vert:before {
  content: '\eb5b';
}
.icon-refresh:before {
  content: '\eb5c';
}
.icon-unfold-less:before {
  content: '\eb5d';
}
.icon-unfold-more:before {
  content: '\eb5e';
}
.icon-arrow-upward:before {
  content: '\eb5f';
}
.icon-subdirectory-arrow-left:before {
  content: '\eb60';
}
.icon-subdirectory-arrow-right:before {
  content: '\eb61';
}
.icon-arrow-downward:before {
  content: '\eb62';
}
.icon-first-page:before {
  content: '\eb63';
}
.icon-last-page:before {
  content: '\eb64';
}
.icon-arrow-left:before {
  content: '\eb65';
}
.icon-arrow-right:before {
  content: '\eb66';
}
.icon-arrow-back-ios:before {
  content: '\eb67';
}
.icon-arrow-forward-ios:before {
  content: '\eb68';
}
.icon-adb:before {
  content: '\eb69';
}
.icon-disc-full:before {
  content: '\eb6a';
}
.icon-do-not-disturb-alt:before {
  content: '\eb6b';
}
.icon-drive-eta:before {
  content: '\eb6c';
}
.icon-event-available:before {
  content: '\eb6d';
}
.icon-event-busy:before {
  content: '\eb6e';
}
.icon-event-note:before {
  content: '\eb6f';
}
.icon-folder-special:before {
  content: '\eb70';
}
.icon-mms:before {
  content: '\eb71';
}
.icon-more:before {
  content: '\eb72';
}
.icon-network-locked:before {
  content: '\eb73';
}
.icon-phone-bluetooth-speaker:before {
  content: '\eb74';
}
.icon-phone-forwarded:before {
  content: '\eb75';
}
.icon-phone-in-talk:before {
  content: '\eb76';
}
.icon-phone-locked:before {
  content: '\eb77';
}
.icon-phone-missed:before {
  content: '\eb78';
}
.icon-phone-paused:before {
  content: '\eb79';
}
.icon-sim-card-alert:before {
  content: '\eb7a';
}
.icon-sms-failed:before {
  content: '\eb7b';
}
.icon-sync-disabled:before {
  content: '\eb7c';
}
.icon-sync-problem:before {
  content: '\eb7d';
}
.icon-system-update:before {
  content: '\eb7e';
}
.icon-tap-and-play:before {
  content: '\eb7f';
}
.icon-vibration:before {
  content: '\eb80';
}
.icon-voice-chat:before {
  content: '\eb81';
}
.icon-vpn-lock:before {
  content: '\eb82';
}
.icon-airline-seat-flat:before {
  content: '\eb83';
}
.icon-airline-seat-flat-angled:before {
  content: '\eb84';
}
.icon-airline-seat-individual-suite:before {
  content: '\eb85';
}
.icon-airline-seat-legroom-extra:before {
  content: '\eb86';
}
.icon-airline-seat-legroom-normal:before {
  content: '\eb87';
}
.icon-airline-seat-legroom-reduced:before {
  content: '\eb88';
}
.icon-airline-seat-recline-extra:before {
  content: '\eb89';
}
.icon-airline-seat-recline-normal:before {
  content: '\eb8a';
}
.icon-confirmation-number:before {
  content: '\eb8b';
}
.icon-live-tv:before {
  content: '\eb8c';
}
.icon-ondemand-video:before {
  content: '\eb8d';
}
.icon-personal-video:before {
  content: '\eb8e';
}
.icon-power:before {
  content: '\eb8f';
}
.icon-wc:before {
  content: '\eb90';
}
.icon-wifi:before {
  content: '\eb91';
}
.icon-enhanced-encryption:before {
  content: '\eb92';
}
.icon-network-check:before {
  content: '\eb93';
}
.icon-no-encryption:before {
  content: '\eb94';
}
.icon-rv-hookup:before {
  content: '\eb95';
}
.icon-do-not-disturb-off:before {
  content: '\eb96';
}
.icon-priority-high:before {
  content: '\eb97';
}
.icon-power-off:before {
  content: '\eb98';
}
.icon-tv-off:before {
  content: '\eb99';
}
.icon-wifi-off:before {
  content: '\eb9a';
}
.icon-phone-callback:before {
  content: '\eb9b';
}
.icon-pie-chart:before {
  content: '\eb9c';
}
.icon-pie-chart-outlined:before {
  content: '\eb9d';
}
.icon-bubble-chart:before {
  content: '\eb9e';
}
.icon-multiline-chart:before {
  content: '\eb9f';
}
.icon-show-chart:before {
  content: '\eba0';
}
.icon-cake:before {
  content: '\eba1';
}
.icon-group:before {
  content: '\eba2';
}
.icon-group-add:before {
  content: '\eba3';
}
.icon-location-city:before {
  content: '\eba4';
}
.icon-mood-bad:before {
  content: '\eba5';
}
.icon-notifications:before {
  content: '\eba6';
}
.icon-notifications-none:before {
  content: '\eba7';
}
.icon-notifications-off:before {
  content: '\eba8';
}
.icon-notifications-on:before {
  content: '\eba9';
}
.icon-notifications-paused:before {
  content: '\ebaa';
}
.icon-pages:before {
  content: '\ebab';
}
.icon-party-mode:before {
  content: '\ebac';
}
.icon-people-outline:before {
  content: '\ebad';
}
.icon-person:before {
  content: '\ebae';
}
.icon-person-add:before {
  content: '\ebaf';
}
.icon-person-outline:before {
  content: '\ebb0';
}
.icon-plus-one:before {
  content: '\ebb1';
}
.icon-public:before {
  content: '\ebb2';
}
.icon-school:before {
  content: '\ebb3';
}
.icon-share:before {
  content: '\ebb4';
}
.icon-whatshot:before {
  content: '\ebb5';
}
.icon-sentiment-dissatisfied:before {
  content: '\ebb6';
}
.icon-sentiment-neutral:before {
  content: '\ebb7';
}
.icon-sentiment-satisfied:before {
  content: '\ebb8';
}
.icon-sentiment-very-dissatisfied:before {
  content: '\ebb9';
}
.icon-sentiment-very-satisfied:before {
  content: '\ebba';
}
.icon-thumb-down-alt:before {
  content: '\ebbb';
}
.icon-thumb-up-alt:before {
  content: '\ebbc';
}
.icon-check-box:before {
  content: '\ebbd';
}
.icon-check-box-outline-blank:before {
  content: '\ebbe';
}
.icon-radio-button-on:before {
  content: '\ebbf';
}
.icon-star:before {
  content: '\ebc0';
}
.icon-star-half:before {
  content: '\ebc1';
}
.icon-star-outline:before {
  content: '\ebc2';
}
.icon-3d-rotation:before {
  content: '\ebc3';
}
.icon-accessibility:before {
  content: '\ebc4';
}
.icon-account-balance:before {
  content: '\ebc5';
}
.icon-account-balance-wallet:before {
  content: '\ebc6';
}
.icon-account-box:before {
  content: '\ebc7';
}
.icon-account-circle:before {
  content: '\ebc8';
}
.icon-add-shopping-cart:before {
  content: '\ebc9';
}
.icon-alarm-off:before {
  content: '\ebca';
}
.icon-alarm-on:before {
  content: '\ebcb';
}
.icon-android:before {
  content: '\ebcc';
}
.icon-announcement:before {
  content: '\ebcd';
}
.icon-aspect-ratio:before {
  content: '\ebce';
}
.icon-assignment:before {
  content: '\ebcf';
}
.icon-assignment-ind:before {
  content: '\ebd0';
}
.icon-assignment-late:before {
  content: '\ebd1';
}
.icon-assignment-return:before {
  content: '\ebd2';
}
.icon-assignment-returned:before {
  content: '\ebd3';
}
.icon-assignment-turned-in:before {
  content: '\ebd4';
}
.icon-autorenew:before {
  content: '\ebd5';
}
.icon-book:before {
  content: '\ebd6';
}
.icon-bookmark:before {
  content: '\ebd7';
}
.icon-bookmark-outline:before {
  content: '\ebd8';
}
.icon-bug-report:before {
  content: '\ebd9';
}
.icon-build:before {
  content: '\ebda';
}
.icon-cached:before {
  content: '\ebdb';
}
.icon-change-history:before {
  content: '\ebdc';
}
.icon-check-circle:before {
  content: '\ebdd';
}
.icon-chrome-reader-mode:before {
  content: '\ebde';
}
.icon-code:before {
  content: '\ebdf';
}
.icon-credit-card:before {
  content: '\ebe0';
}
.icon-dashboard:before {
  content: '\ebe1';
}
.icon-delete:before {
  content: '\ebe2';
}
.icon-description:before {
  content: '\ebe3';
}
.icon-dns:before {
  content: '\ebe4';
}
.icon-done:before {
  content: '\ebe5';
}
.icon-done-all:before {
  content: '\ebe6';
}
.icon-exit-to-app:before {
  content: '\ebe7';
}
.icon-explore:before {
  content: '\ebe8';
}
.icon-extension:before {
  content: '\ebe9';
}
.icon-face:before {
  content: '\ebea';
}
.icon-favorite:before {
  content: '\ebeb';
}
.icon-favorite-outline:before {
  content: '\ebec';
}
.icon-find-in-page:before {
  content: '\ebed';
}
.icon-find-replace:before {
  content: '\ebee';
}
.icon-flip-to-back:before {
  content: '\ebef';
}
.icon-flip-to-front:before {
  content: '\ebf0';
}
.icon-group-work:before {
  content: '\ebf1';
}
.icon-help:before {
  content: '\ebf2';
}
.icon-highlight-remove:before {
  content: '\ebf3';
}
.icon-history:before {
  content: '\ebf4';
}
.icon-home:before {
  content: '\ebf5';
}
.icon-hourglass-empty:before {
  content: '\ebf6';
}
.icon-hourglass-full:before {
  content: '\ebf7';
}
.icon-https:before {
  content: '\ebf8';
}
.icon-info:before {
  content: '\ebf9';
}
.icon-info-outline:before {
  content: '\ebfa';
}
.icon-input:before {
  content: '\ebfb';
}
.icon-invert-colors-on:before {
  content: '\ebfc';
}
.icon-label:before {
  content: '\ebfd';
}
.icon-label-outline:before {
  content: '\ebfe';
}
.icon-language:before {
  content: '\ebff';
}
.icon-launch:before {
  content: '\ec00';
}
.icon-list:before {
  content: '\ec01';
}
.icon-lock-open:before {
  content: '\ec02';
}
.icon-lock-outline:before {
  content: '\ec03';
}
.icon-loyalty:before {
  content: '\ec04';
}
.icon-markunread-mailbox:before {
  content: '\ec05';
}
.icon-note-add:before {
  content: '\ec06';
}
.icon-open-in-browser:before {
  content: '\ec07';
}
.icon-open-with:before {
  content: '\ec08';
}
.icon-pageview:before {
  content: '\ec09';
}
.icon-perm-camera-mic:before {
  content: '\ec0a';
}
.icon-perm-contact-calendar:before {
  content: '\ec0b';
}
.icon-perm-data-setting:before {
  content: '\ec0c';
}
.icon-perm-device-information:before {
  content: '\ec0d';
}
.icon-perm-media:before {
  content: '\ec0e';
}
.icon-perm-phone-msg:before {
  content: '\ec0f';
}
.icon-perm-scan-wifi:before {
  content: '\ec10';
}
.icon-picture-in-picture:before {
  content: '\ec11';
}
.icon-polymer:before {
  content: '\ec12';
}
.icon-power-settings-new:before {
  content: '\ec13';
}
.icon-receipt:before {
  content: '\ec14';
}
.icon-redeem:before {
  content: '\ec15';
}
.icon-search:before {
  content: '\ec16';
}
.icon-settings:before {
  content: '\ec17';
}
.icon-settings-applications:before {
  content: '\ec18';
}
.icon-settings-backup-restore:before {
  content: '\ec19';
}
.icon-settings-bluetooth:before {
  content: '\ec1a';
}
.icon-settings-cell:before {
  content: '\ec1b';
}
.icon-settings-display:before {
  content: '\ec1c';
}
.icon-settings-ethernet:before {
  content: '\ec1d';
}
.icon-settings-input-antenna:before {
  content: '\ec1e';
}
.icon-settings-input-component:before {
  content: '\ec1f';
}
.icon-settings-input-hdmi:before {
  content: '\ec20';
}
.icon-settings-input-svideo:before {
  content: '\ec21';
}
.icon-settings-overscan:before {
  content: '\ec22';
}
.icon-settings-phone:before {
  content: '\ec23';
}
.icon-settings-power:before {
  content: '\ec24';
}
.icon-settings-remote:before {
  content: '\ec25';
}
.icon-settings-voice:before {
  content: '\ec26';
}
.icon-shop:before {
  content: '\ec27';
}
.icon-shop-two:before {
  content: '\ec28';
}
.icon-shopping-basket:before {
  content: '\ec29';
}
.icon-speaker-notes:before {
  content: '\ec2a';
}
.icon-spellcheck:before {
  content: '\ec2b';
}
.icon-stars:before {
  content: '\ec2c';
}
.icon-subject:before {
  content: '\ec2d';
}
.icon-supervisor-account:before {
  content: '\ec2e';
}
.icon-swap-horiz:before {
  content: '\ec2f';
}
.icon-swap-vert:before {
  content: '\ec30';
}
.icon-swap-vertical-circle:before {
  content: '\ec31';
}
.icon-system-update-tv:before {
  content: '\ec32';
}
.icon-tab:before {
  content: '\ec33';
}
.icon-tab-unselected:before {
  content: '\ec34';
}
.icon-thumb-down:before {
  content: '\ec35';
}
.icon-thumb-up:before {
  content: '\ec36';
}
.icon-thumbs-up-down:before {
  content: '\ec37';
}
.icon-toc:before {
  content: '\ec38';
}
.icon-today:before {
  content: '\ec39';
}
.icon-toll:before {
  content: '\ec3a';
}
.icon-track-changes:before {
  content: '\ec3b';
}
.icon-translate:before {
  content: '\ec3c';
}
.icon-trending-down:before {
  content: '\ec3d';
}
.icon-trending-neutral:before {
  content: '\ec3e';
}
.icon-trending-up:before {
  content: '\ec3f';
}
.icon-verified-user:before {
  content: '\ec40';
}
.icon-view-agenda:before {
  content: '\ec41';
}
.icon-view-array:before {
  content: '\ec42';
}
.icon-view-carousel:before {
  content: '\ec43';
}
.icon-view-column:before {
  content: '\ec44';
}
.icon-view-day:before {
  content: '\ec45';
}
.icon-view-headline:before {
  content: '\ec46';
}
.icon-view-list:before {
  content: '\ec47';
}
.icon-view-module:before {
  content: '\ec48';
}
.icon-view-quilt:before {
  content: '\ec49';
}
.icon-view-stream:before {
  content: '\ec4a';
}
.icon-view-week:before {
  content: '\ec4b';
}
.icon-visibility-off:before {
  content: '\ec4c';
}
.icon-card-membership:before {
  content: '\ec4d';
}
.icon-card-travel:before {
  content: '\ec4e';
}
.icon-work:before {
  content: '\ec4f';
}
.icon-youtube-searched-for:before {
  content: '\ec50';
}
.icon-eject:before {
  content: '\ec51';
}
.icon-camera-enhance:before {
  content: '\ec52';
}
.icon-help-outline:before {
  content: '\ec53';
}
.icon-reorder:before {
  content: '\ec54';
}
.icon-zoom-in:before {
  content: '\ec55';
}
.icon-zoom-out:before {
  content: '\ec56';
}
.icon-http:before {
  content: '\ec57';
}
.icon-event-seat:before {
  content: '\ec58';
}
.icon-flight-land:before {
  content: '\ec59';
}
.icon-flight-takeoff:before {
  content: '\ec5a';
}
.icon-play-for-work:before {
  content: '\ec5b';
}
.icon-gif:before {
  content: '\ec5c';
}
.icon-indeterminate-check-box:before {
  content: '\ec5d';
}
.icon-offline-pin:before {
  content: '\ec5e';
}
.icon-all-out:before {
  content: '\ec5f';
}
.icon-copyright:before {
  content: '\ec60';
}
.icon-fingerprint:before {
  content: '\ec61';
}
.icon-gavel:before {
  content: '\ec62';
}
.icon-lightbulb-outline:before {
  content: '\ec63';
}
.icon-picture-in-picture-alt:before {
  content: '\ec64';
}
.icon-important-devices:before {
  content: '\ec65';
}
.icon-touch-app:before {
  content: '\ec66';
}
.icon-accessible:before {
  content: '\ec67';
}
.icon-compare-arrows:before {
  content: '\ec68';
}
.icon-date-range:before {
  content: '\ec69';
}
.icon-donut-large:before {
  content: '\ec6a';
}
.icon-donut-small:before {
  content: '\ec6b';
}
.icon-line-style:before {
  content: '\ec6c';
}
.icon-line-weight:before {
  content: '\ec6d';
}
.icon-motorcycle:before {
  content: '\ec6e';
}
.icon-opacity:before {
  content: '\ec6f';
}
.icon-pets:before {
  content: '\ec70';
}
.icon-pregnant-woman:before {
  content: '\ec71';
}
.icon-record-voice-over:before {
  content: '\ec72';
}
.icon-rounded-corner:before {
  content: '\ec73';
}
.icon-rowing:before {
  content: '\ec74';
}
.icon-timeline:before {
  content: '\ec75';
}
.icon-update:before {
  content: '\ec76';
}
.icon-watch-later:before {
  content: '\ec77';
}
.icon-pan-tool:before {
  content: '\ec78';
}
.icon-euro-symbol:before {
  content: '\ec79';
}
.icon-g-translate:before {
  content: '\ec7a';
}
.icon-remove-shopping-cart:before {
  content: '\ec7b';
}
.icon-restore-page:before {
  content: '\ec7c';
}
.icon-speaker-notes-off:before {
  content: '\ec7d';
}
.icon-delete-forever:before {
  content: '\ec7e';
}
.icon-accessibility-new:before {
  content: '\ec7f';
}
.icon-check-circle-outline:before {
  content: '\ec80';
}
.icon-delete-outline:before {
  content: '\ec81';
}
.icon-done-outline:before {
  content: '\ec82';
}
.icon-maximize:before {
  content: '\ec83';
}
.icon-minimize:before {
  content: '\ec84';
}
.icon-offline-bolt:before {
  content: '\ec85';
}
.icon-swap-horizontal-circle:before {
  content: '\ec86';
}
.icon-accessible-forward:before {
  content: '\ec87';
}
.icon-calendar-today:before {
  content: '\ec88';
}
.icon-calendar-view-day:before {
  content: '\ec89';
}
.icon-label-important:before {
  content: '\ec8a';
}
.icon-restore-from-trash:before {
  content: '\ec8b';
}
.icon-supervised-user-circle:before {
  content: '\ec8c';
}
.icon-text-rotate-up:before {
  content: '\ec8d';
}
.icon-text-rotate-vertical:before {
  content: '\ec8e';
}
.icon-text-rotation-angledown:before {
  content: '\ec8f';
}
.icon-text-rotation-angleup:before {
  content: '\ec90';
}
.icon-text-rotation-down:before {
  content: '\ec91';
}
.icon-text-rotation-none:before {
  content: '\ec92';
}
.icon-commute:before {
  content: '\ec93';
}
.icon-arrow-right-alt:before {
  content: '\ec94';
}
.icon-work-off:before {
  content: '\ec95';
}
.icon-work-outline:before {
  content: '\ec96';
}
.icon-drag-indicator:before {
  content: '\ec97';
}
.icon-horizontal-split:before {
  content: '\ec98';
}
.icon-label-important-outline:before {
  content: '\ec99';
}
.icon-vertical-split:before {
  content: '\ec9a';
}
.icon-voice-over-off:before {
  content: '\ec9b';
}
.icon-segment:before {
  content: '\ec9c';
}
.icon-contact-support:before {
  content: '\ec9d';
}
.icon-compress:before {
  content: '\ec9e';
}
.icon-filter-list-alt:before {
  content: '\ec9f';
}
.icon-expand:before {
  content: '\eca0';
}
.icon-edit-off:before {
  content: '\eca1';
}
.icon-10k:before {
  content: '\eca2';
}
.icon-10mp:before {
  content: '\eca3';
}
.icon-11mp:before {
  content: '\eca4';
}
.icon-12mp:before {
  content: '\eca5';
}
.icon-13mp:before {
  content: '\eca6';
}
.icon-14mp:before {
  content: '\eca7';
}
.icon-15mp:before {
  content: '\eca8';
}
.icon-16mp:before {
  content: '\eca9';
}
.icon-17mp:before {
  content: '\ecaa';
}
.icon-18mp:before {
  content: '\ecab';
}
.icon-19mp:before {
  content: '\ecac';
}
.icon-1k:before {
  content: '\ecad';
}
.icon-1k-plus:before {
  content: '\ecae';
}
.icon-20mp:before {
  content: '\ecaf';
}
.icon-21mp:before {
  content: '\ecb0';
}
.icon-22mp:before {
  content: '\ecb1';
}
.icon-23mp:before {
  content: '\ecb2';
}
.icon-24mp:before {
  content: '\ecb3';
}
.icon-2k:before {
  content: '\ecb4';
}
.icon-2k-plus:before {
  content: '\ecb5';
}
.icon-2mp:before {
  content: '\ecb6';
}
.icon-3k:before {
  content: '\ecb7';
}
.icon-3k-plus:before {
  content: '\ecb8';
}
.icon-3mp:before {
  content: '\ecb9';
}
.icon-4k-plus:before {
  content: '\ecba';
}
.icon-4mp:before {
  content: '\ecbb';
}
.icon-5k:before {
  content: '\ecbc';
}
.icon-5k-plus:before {
  content: '\ecbd';
}
.icon-5mp:before {
  content: '\ecbe';
}
.icon-6k:before {
  content: '\ecbf';
}
.icon-6k-plus:before {
  content: '\ecc0';
}
.icon-6mp:before {
  content: '\ecc1';
}
.icon-7k:before {
  content: '\ecc2';
}
.icon-7k-plus:before {
  content: '\ecc3';
}
.icon-7mp:before {
  content: '\ecc4';
}
.icon-8k:before {
  content: '\ecc5';
}
.icon-8k-plus:before {
  content: '\ecc6';
}
.icon-8mp:before {
  content: '\ecc7';
}
.icon-9k:before {
  content: '\ecc8';
}
.icon-9k-plus:before {
  content: '\ecc9';
}
.icon-9mp:before {
  content: '\ecca';
}
.icon-account-tree:before {
  content: '\eccb';
}
.icon-add-chart:before {
  content: '\eccc';
}
.icon-add-ic-call:before {
  content: '\eccd';
}
.icon-add-moderator:before {
  content: '\ecce';
}
.icon-all-inbox:before {
  content: '\eccf';
}
.icon-approval:before {
  content: '\ecd0';
}
.icon-assistant-direction:before {
  content: '\ecd1';
}
.icon-assistant-navigation:before {
  content: '\ecd2';
}
.icon-bookmarks:before {
  content: '\ecd3';
}
.icon-bus-alert:before {
  content: '\ecd4';
}
.icon-cases:before {
  content: '\ecd5';
}
.icon-circle-notifications:before {
  content: '\ecd6';
}
.icon-closed-caption-off:before {
  content: '\ecd7';
}
.icon-connected-tv:before {
  content: '\ecd8';
}
.icon-dangerous:before {
  content: '\ecd9';
}
.icon-dashboard-customize:before {
  content: '\ecda';
}
.icon-desktop-access-disabled:before {
  content: '\ecdb';
}
.icon-drive-file-move-outline:before {
  content: '\ecdc';
}
.icon-drive-file-rename-outline:before {
  content: '\ecdd';
}
.icon-drive-folder-upload:before {
  content: '\ecde';
}
.icon-duo:before {
  content: '\ecdf';
}
.icon-explore-off:before {
  content: '\ece0';
}
.icon-file-download-done:before {
  content: '\ece1';
}
.icon-rtt:before {
  content: '\ece2';
}
.icon-grid-view:before {
  content: '\ece3';
}
.icon-hail:before {
  content: '\ece4';
}
.icon-home-filled:before {
  content: '\ece5';
}
.icon-imagesearch-roller:before {
  content: '\ece6';
}
.icon-label-off:before {
  content: '\ece7';
}
.icon-library-add-check:before {
  content: '\ece8';
}
.icon-logout:before {
  content: '\ece9';
}
.icon-margin:before {
  content: '\ecea';
}
.icon-mark-as-unread:before {
  content: '\eceb';
}
.icon-menu-open:before {
  content: '\ecec';
}
.icon-mp:before {
  content: '\eced';
}
.icon-offline-share:before {
  content: '\ecee';
}
.icon-padding:before {
  content: '\ecef';
}
.icon-panorama-photosphere:before {
  content: '\ecf0';
}
.icon-panorama-photosphere-select:before {
  content: '\ecf1';
}
.icon-person-add-disabled:before {
  content: '\ecf2';
}
.icon-phone-disabled:before {
  content: '\ecf3';
}
.icon-phone-enabled:before {
  content: '\ecf4';
}
.icon-pivot-table-chart:before {
  content: '\ecf5';
}
.icon-print-disabled:before {
  content: '\ecf6';
}
.icon-railway-alert:before {
  content: '\ecf7';
}
.icon-recommend:before {
  content: '\ecf8';
}
.icon-remove-done:before {
  content: '\ecf9';
}
.icon-remove-moderator:before {
  content: '\ecfa';
}
.icon-repeat-on:before {
  content: '\ecfb';
}
.icon-repeat-one-on:before {
  content: '\ecfc';
}
.icon-replay-circle-filled:before {
  content: '\ecfd';
}
.icon-reset-tv:before {
  content: '\ecfe';
}
.icon-sd:before {
  content: '\ecff';
}
.icon-shield:before {
  content: '\ed00';
}
.icon-shuffle-on:before {
  content: '\ed01';
}
.icon-speed:before {
  content: '\ed02';
}
.icon-stacked-bar-chart:before {
  content: '\ed03';
}
.icon-stream:before {
  content: '\ed04';
}
.icon-swipe:before {
  content: '\ed05';
}
.icon-switch-account:before {
  content: '\ed06';
}
.icon-tag:before {
  content: '\ed07';
}
.icon-thumb-down-off-alt:before {
  content: '\ed08';
}
.icon-thumb-up-off-alt:before {
  content: '\ed09';
}
.icon-toggle-off:before {
  content: '\ed0a';
}
.icon-toggle-on:before {
  content: '\ed0b';
}
.icon-two-wheeler:before {
  content: '\ed0c';
}
.icon-upload-file:before {
  content: '\ed0d';
}
.icon-view-in-ar:before {
  content: '\ed0e';
}
.icon-waterfall-chart:before {
  content: '\ed0f';
}
.icon-wb-shade:before {
  content: '\ed10';
}
.icon-wb-twighlight:before {
  content: '\ed11';
}
.icon-home-work:before {
  content: '\ed12';
}
.icon-schedule-send:before {
  content: '\ed13';
}
.icon-bolt:before {
  content: '\ed14';
}
.icon-send-and-archive:before {
  content: '\ed15';
}
.icon-workspaces-filled:before {
  content: '\ed16';
}
.icon-file-present:before {
  content: '\ed17';
}
.icon-workspaces-outline:before {
  content: '\ed18';
}
.icon-fit-screen:before {
  content: '\ed19';
}
.icon-saved-search:before {
  content: '\ed1a';
}
.icon-storefront:before {
  content: '\ed1b';
}
.icon-amp-stories:before {
  content: '\ed1c';
}
.icon-dynamic-feed:before {
  content: '\ed1d';
}
.icon-euro:before {
  content: '\ed1e';
}
.icon-height:before {
  content: '\ed1f';
}
.icon-policy:before {
  content: '\ed20';
}
.icon-sync-alt:before {
  content: '\ed21';
}
.icon-menu-book:before {
  content: '\ed22';
}
.icon-emoji-flags:before {
  content: '\ed23';
}
.icon-emoji-food-beverage:before {
  content: '\ed24';
}
.icon-emoji-nature:before {
  content: '\ed25';
}
.icon-emoji-people:before {
  content: '\ed26';
}
.icon-emoji-symbols:before {
  content: '\ed27';
}
.icon-emoji-transportation:before {
  content: '\ed28';
}
.icon-post-add:before {
  content: '\ed29';
}
.icon-people-alt:before {
  content: '\ed2a';
}
.icon-emoji-emotions:before {
  content: '\ed2b';
}
.icon-emoji-events:before {
  content: '\ed2c';
}
.icon-emoji-objects:before {
  content: '\ed2d';
}
.icon-sports-basketball:before {
  content: '\ed2e';
}
.icon-sports-cricket:before {
  content: '\ed2f';
}
.icon-sports-esports:before {
  content: '\ed30';
}
.icon-sports-football:before {
  content: '\ed31';
}
.icon-sports-golf:before {
  content: '\ed32';
}
.icon-sports-hockey:before {
  content: '\ed33';
}
.icon-sports-mma:before {
  content: '\ed34';
}
.icon-sports-motorsports:before {
  content: '\ed35';
}
.icon-sports-rugby:before {
  content: '\ed36';
}
.icon-sports-soccer:before {
  content: '\ed37';
}
.icon-sports:before {
  content: '\ed38';
}
.icon-sports-volleyball:before {
  content: '\ed39';
}
.icon-sports-tennis:before {
  content: '\ed3a';
}
.icon-sports-handball:before {
  content: '\ed3b';
}
.icon-sports-kabaddi:before {
  content: '\ed3c';
}
.icon-eco:before {
  content: '\ed3d';
}
.icon-museum:before {
  content: '\ed3e';
}
.icon-flip-camera-android:before {
  content: '\ed3f';
}
.icon-flip-camera-ios:before {
  content: '\ed40';
}
.icon-cancel-schedule-send:before {
  content: '\ed41';
}
.icon-apartment:before {
  content: '\ed42';
}
.icon-bathtub:before {
  content: '\ed43';
}
.icon-deck:before {
  content: '\ed44';
}
.icon-fireplace:before {
  content: '\ed45';
}
.icon-house:before {
  content: '\ed46';
}
.icon-king-bed:before {
  content: '\ed47';
}
.icon-nights-stay:before {
  content: '\ed48';
}
.icon-outdoor-grill:before {
  content: '\ed49';
}
.icon-single-bed:before {
  content: '\ed4a';
}
.icon-square-foot:before {
  content: '\ed4b';
}
.icon-double-arrow:before {
  content: '\ed4c';
}
.icon-sports-baseball:before {
  content: '\ed4d';
}
.icon-attractions:before {
  content: '\ed4e';
}
.icon-bakery-dining:before {
  content: '\ed4f';
}
.icon-breakfast-dining:before {
  content: '\ed50';
}
.icon-car-rental:before {
  content: '\ed51';
}
.icon-car-repair:before {
  content: '\ed52';
}
.icon-dinner-dining:before {
  content: '\ed53';
}
.icon-dry-cleaning:before {
  content: '\ed54';
}
.icon-hardware:before {
  content: '\ed55';
}
.icon-liquor:before {
  content: '\ed56';
}
.icon-lunch-dining:before {
  content: '\ed57';
}
.icon-nightlife:before {
  content: '\ed58';
}
.icon-park:before {
  content: '\ed59';
}
.icon-ramen-dining:before {
  content: '\ed5a';
}
.icon-celebration:before {
  content: '\ed5b';
}
.icon-theater-comedy:before {
  content: '\ed5c';
}
.icon-badge:before {
  content: '\ed5d';
}
.icon-festival:before {
  content: '\ed5e';
}
.icon-icecream:before {
  content: '\ed5f';
}
.icon-volunteer-activism:before {
  content: '\ed60';
}
.icon-contactless:before {
  content: '\ed61';
}
.icon-delivery-dining:before {
  content: '\ed62';
}
.icon-brunch-dining:before {
  content: '\ed63';
}
.icon-takeout-dining:before {
  content: '\ed64';
}
.icon-ac-unit:before {
  content: '\ed65';
}
.icon-airport-shuttle:before {
  content: '\ed66';
}
.icon-all-inclusive:before {
  content: '\ed67';
}
.icon-beach-access:before {
  content: '\ed68';
}
.icon-business-center:before {
  content: '\ed69';
}
.icon-casino:before {
  content: '\ed6a';
}
.icon-child-care:before {
  content: '\ed6b';
}
.icon-child-friendly:before {
  content: '\ed6c';
}
.icon-fitness-center:before {
  content: '\ed6d';
}
.icon-golf-course:before {
  content: '\ed6e';
}
.icon-hot-tub:before {
  content: '\ed6f';
}
.icon-kitchen:before {
  content: '\ed70';
}
.icon-pool:before {
  content: '\ed71';
}
.icon-room-service:before {
  content: '\ed72';
}
.icon-smoke-free:before {
  content: '\ed73';
}
.icon-smoking-rooms:before {
  content: '\ed74';
}
.icon-spa:before {
  content: '\ed75';
}
.icon-no-meeting-room:before {
  content: '\ed76';
}
.icon-meeting-room:before {
  content: '\ed77';
}
.icon-goat:before {
  content: '\ed78';
}
.icon-5g:before {
  content: '\ed79';
}
.icon-ad-units:before {
  content: '\ed7a';
}
.icon-add-business:before {
  content: '\ed7b';
}
.icon-add-location-alt:before {
  content: '\ed7c';
}
.icon-add-road:before {
  content: '\ed7d';
}
.icon-add-to-drive:before {
  content: '\ed7e';
}
.icon-addchart:before {
  content: '\ed7f';
}
.icon-admin-panel-settings:before {
  content: '\ed80';
}
.icon-agriculture:before {
  content: '\ed81';
}
.icon-alt-route:before {
  content: '\ed82';
}
.icon-analytics:before {
  content: '\ed83';
}
.icon-anchor:before {
  content: '\ed84';
}
.icon-animation:before {
  content: '\ed85';
}
.icon-api:before {
  content: '\ed86';
}
.icon-app-blocking:before {
  content: '\ed87';
}
.icon-app-registration:before {
  content: '\ed88';
}
.icon-app-settings-alt:before {
  content: '\ed89';
}
.icon-architecture:before {
  content: '\ed8a';
}
.icon-arrow-circle-down:before {
  content: '\ed8b';
}
.icon-arrow-circle-up:before {
  content: '\ed8c';
}
.icon-article:before {
  content: '\ed8d';
}
.icon-attach-email:before {
  content: '\ed8e';
}
.icon-auto-awesome:before {
  content: '\ed8f';
}
.icon-auto-awesome-mosaic:before {
  content: '\ed90';
}
.icon-auto-awesome-motion:before {
  content: '\ed91';
}
.icon-auto-delete:before {
  content: '\ed92';
}
.icon-auto-fix-high:before {
  content: '\ed93';
}
.icon-auto-fix-normal:before {
  content: '\ed94';
}
.icon-auto-fix-off:before {
  content: '\ed95';
}
.icon-auto-stories:before {
  content: '\ed96';
}
.icon-baby-changing-station:before {
  content: '\ed97';
}
.icon-backpack:before {
  content: '\ed98';
}
.icon-backup-table:before {
  content: '\ed99';
}
.icon-batch-prediction:before {
  content: '\ed9a';
}
.icon-bedtime:before {
  content: '\ed9b';
}
.icon-bento:before {
  content: '\ed9c';
}
.icon-bike-scooter:before {
  content: '\ed9d';
}
.icon-biotech:before {
  content: '\ed9e';
}
.icon-block-flipped:before {
  content: '\ed9f';
}
.icon-browser-not-supported:before {
  content: '\eda0';
}
.icon-build-circle:before {
  content: '\eda1';
}
.icon-calculate:before {
  content: '\eda2';
}
.icon-campaign:before {
  content: '\eda3';
}
.icon-carpenter:before {
  content: '\eda4';
}
.icon-cast-for-education:before {
  content: '\eda5';
}
.icon-charging-station:before {
  content: '\eda6';
}
.icon-checkroom:before {
  content: '\eda7';
}
.icon-circle:before {
  content: '\eda8';
}
.icon-cleaning-services:before {
  content: '\eda9';
}
.icon-close-fullscreen:before {
  content: '\edaa';
}
.icon-closed-caption-disabled:before {
  content: '\edab';
}
.icon-comment-bank:before {
  content: '\edac';
}
.icon-construction:before {
  content: '\edad';
}
.icon-corporate-fare:before {
  content: '\edae';
}
.icon-countertops:before {
  content: '\edaf';
}
.icon-design-services:before {
  content: '\edb0';
}
.icon-directions-off:before {
  content: '\edb1';
}
.icon-dirty-lens:before {
  content: '\edb2';
}
.icon-do-not-step:before {
  content: '\edb3';
}
.icon-do-not-touch:before {
  content: '\edb4';
}
.icon-domain-verification:before {
  content: '\edb5';
}
.icon-drive-file-move:before {
  content: '\edb6';
}
.icon-dry:before {
  content: '\edb7';
}
.icon-dynamic-form:before {
  content: '\edb8';
}
.icon-east:before {
  content: '\edb9';
}
.icon-edit-road:before {
  content: '\edba';
}
.icon-electric-bike:before {
  content: '\edbb';
}
.icon-electric-car:before {
  content: '\edbc';
}
.icon-electric-moped:before {
  content: '\edbd';
}
.icon-electric-rickshaw:before {
  content: '\edbe';
}
.icon-electric-scooter:before {
  content: '\edbf';
}
.icon-electrical-services:before {
  content: '\edc0';
}
.icon-elevator:before {
  content: '\edc1';
}
.icon-engineering:before {
  content: '\edc2';
}
.icon-escalator:before {
  content: '\edc3';
}
.icon-escalator-warning:before {
  content: '\edc4';
}
.icon-face-retouching-natural:before {
  content: '\edc5';
}
.icon-fact-check:before {
  content: '\edc6';
}
.icon-family-restroom:before {
  content: '\edc7';
}
.icon-fence:before {
  content: '\edc8';
}
.icon-filter-alt:before {
  content: '\edc9';
}
.icon-fire-extinguisher:before {
  content: '\edca';
}
.icon-flaky:before {
  content: '\edcb';
}
.icon-food-bank:before {
  content: '\edcc';
}
.icon-forward-to-inbox:before {
  content: '\edcd';
}
.icon-foundation:before {
  content: '\edce';
}
.icon-grading:before {
  content: '\edcf';
}
.icon-grass:before {
  content: '\edd0';
}
.icon-handyman:before {
  content: '\edd1';
}
.icon-hdr-enhanced-select:before {
  content: '\edd2';
}
.icon-hearing-disabled:before {
  content: '\edd3';
}
.icon-help-center:before {
  content: '\edd4';
}
.icon-highlight-alt:before {
  content: '\edd5';
}
.icon-history-edu:before {
  content: '\edd6';
}
.icon-history-toggle-off:before {
  content: '\edd7';
}
.icon-home-repair-service:before {
  content: '\edd8';
}
.icon-horizontal-rule:before {
  content: '\edd9';
}
.icon-hourglass-bottom:before {
  content: '\edda';
}
.icon-hourglass-disabled:before {
  content: '\eddb';
}
.icon-hourglass-top:before {
  content: '\eddc';
}
.icon-house-siding:before {
  content: '\eddd';
}
.icon-hvac:before {
  content: '\edde';
}
.icon-image-not-supported:before {
  content: '\eddf';
}
.icon-insights:before {
  content: '\ede0';
}
.icon-integration-instructions:before {
  content: '\ede1';
}
.icon-ios-share:before {
  content: '\ede2';
}
.icon-legend-toggle:before {
  content: '\ede3';
}
.icon-local-fire-department:before {
  content: '\ede4';
}
.icon-local-police:before {
  content: '\ede5';
}
.icon-location-pin:before {
  content: '\ede6';
}
.icon-lock-clock:before {
  content: '\ede7';
}
.icon-login:before {
  content: '\ede8';
}
.icon-maps-ugc:before {
  content: '\ede9';
}
.icon-mark-chat-read:before {
  content: '\edea';
}
.icon-mark-chat-unread:before {
  content: '\edeb';
}
.icon-mark-email-read:before {
  content: '\edec';
}
.icon-mark-email-unread:before {
  content: '\eded';
}
.icon-mediation:before {
  content: '\edee';
}
.icon-medical-services:before {
  content: '\edef';
}
.icon-mic-external-off:before {
  content: '\edf0';
}
.icon-mic-external-on:before {
  content: '\edf1';
}
.icon-microwave:before {
  content: '\edf2';
}
.icon-military-tech:before {
  content: '\edf3';
}
.icon-miscellaneous-services:before {
  content: '\edf4';
}
.icon-model-training:before {
  content: '\edf5';
}
.icon-monitor:before {
  content: '\edf6';
}
.icon-moped:before {
  content: '\edf7';
}
.icon-more-time:before {
  content: '\edf8';
}
.icon-motion-photos-off:before {
  content: '\edf9';
}
.icon-motion-photos-on:before {
  content: '\edfa';
}
.icon-motion-photos-paused:before {
  content: '\edfb';
}
.icon-multiple-stop:before {
  content: '\edfc';
}
.icon-nat:before {
  content: '\edfd';
}
.icon-near-me-disabled:before {
  content: '\edfe';
}
.icon-next-plan:before {
  content: '\edff';
}
.icon-night-shelter:before {
  content: '\ee00';
}
.icon-nightlight-round:before {
  content: '\ee01';
}
.icon-no-cell:before {
  content: '\ee02';
}
.icon-no-drinks:before {
  content: '\ee03';
}
.icon-no-flash:before {
  content: '\ee04';
}
.icon-no-food:before {
  content: '\ee05';
}
.icon-no-meals:before {
  content: '\ee06';
}
.icon-no-photography:before {
  content: '\ee07';
}
.icon-no-stroller:before {
  content: '\ee08';
}
.icon-no-transfer:before {
  content: '\ee09';
}
.icon-north:before {
  content: '\ee0a';
}
.icon-north-east:before {
  content: '\ee0b';
}
.icon-north-west:before {
  content: '\ee0c';
}
.icon-not-accessible:before {
  content: '\ee0d';
}
.icon-not-started:before {
  content: '\ee0e';
}
.icon-online-prediction:before {
  content: '\ee0f';
}
.icon-open-in-full:before {
  content: '\ee10';
}
.icon-outbox:before {
  content: '\ee11';
}
.icon-outgoing-mail:before {
  content: '\ee12';
}
.icon-outlet:before {
  content: '\ee13';
}
.icon-panorama-horizontal-select:before {
  content: '\ee14';
}
.icon-panorama-vertical-select:before {
  content: '\ee15';
}
.icon-panorama-wide-angle-select:before {
  content: '\ee16';
}
.icon-payments:before {
  content: '\ee17';
}
.icon-pedal-bike:before {
  content: '\ee18';
}
.icon-pending:before {
  content: '\ee19';
}
.icon-pending-actions:before {
  content: '\ee1a';
}
.icon-person-add-alt:before {
  content: '\ee1b';
}
.icon-person-add-alt-1:before {
  content: '\ee1c';
}
.icon-person-remove:before {
  content: '\ee1d';
}
.icon-person-search:before {
  content: '\ee1e';
}
.icon-pest-control:before {
  content: '\ee1f';
}
.icon-pest-control-rodent:before {
  content: '\ee20';
}
.icon-photo-camera-back:before {
  content: '\ee21';
}
.icon-photo-camera-front:before {
  content: '\ee22';
}
.icon-plagiarism:before {
  content: '\ee23';
}
.icon-play-disabled:before {
  content: '\ee24';
}
.icon-plumbing:before {
  content: '\ee25';
}
.icon-point-of-sale:before {
  content: '\ee26';
}
.icon-preview:before {
  content: '\ee27';
}
.icon-privacy-tip:before {
  content: '\ee28';
}
.icon-psychology:before {
  content: '\ee29';
}
.icon-public-off:before {
  content: '\ee2a';
}
.icon-push-pin:before {
  content: '\ee2b';
}
.icon-qr-code:before {
  content: '\ee2c';
}
.icon-qr-code-scanner:before {
  content: '\ee2d';
}
.icon-quickreply:before {
  content: '\ee2e';
}
.icon-read-more:before {
  content: '\ee2f';
}
.icon-receipt-long:before {
  content: '\ee30';
}
.icon-request-quote:before {
  content: '\ee31';
}
.icon-rice-bowl:before {
  content: '\ee32';
}
.icon-roofing:before {
  content: '\ee33';
}
.icon-room-preferences:before {
  content: '\ee34';
}
.icon-rule:before {
  content: '\ee35';
}
.icon-rule-folder:before {
  content: '\ee36';
}
.icon-run-circle:before {
  content: '\ee37';
}
.icon-science:before {
  content: '\ee38';
}
.icon-screen-search-desktop:before {
  content: '\ee39';
}
.icon-search-off:before {
  content: '\ee3a';
}
.icon-self-improvement:before {
  content: '\ee3b';
}
.icon-sensor-door:before {
  content: '\ee3c';
}
.icon-sensor-window:before {
  content: '\ee3d';
}
.icon-set-meal:before {
  content: '\ee3e';
}
.icon-shopping-bag:before {
  content: '\ee3f';
}
.icon-signal-cellular-0-bar:before {
  content: '\ee40';
}
.icon-signal-wifi-0-bar:before {
  content: '\ee41';
}
.icon-smart-button:before {
  content: '\ee42';
}
.icon-snippet-folder:before {
  content: '\ee43';
}
.icon-soap:before {
  content: '\ee44';
}
.icon-source:before {
  content: '\ee45';
}
.icon-south:before {
  content: '\ee46';
}
.icon-south-east:before {
  content: '\ee47';
}
.icon-south-west:before {
  content: '\ee48';
}
.icon-sports-bar:before {
  content: '\ee49';
}
.icon-stairs:before {
  content: '\ee4a';
}
.icon-star-outline1:before {
  content: '\ee4b';
}
.icon-star-rate:before {
  content: '\ee4c';
}
.icon-sticky-note-2:before {
  content: '\ee4d';
}
.icon-stop-circle:before {
  content: '\ee4e';
}
.icon-stroller:before {
  content: '\ee4f';
}
.icon-subscript:before {
  content: '\ee50';
}
.icon-subtitles-off:before {
  content: '\ee51';
}
.icon-superscript:before {
  content: '\ee52';
}
.icon-support:before {
  content: '\ee53';
}
.icon-support-agent:before {
  content: '\ee54';
}
.icon-switch-left:before {
  content: '\ee55';
}
.icon-switch-right:before {
  content: '\ee56';
}
.icon-table-rows:before {
  content: '\ee57';
}
.icon-table-view:before {
  content: '\ee58';
}
.icon-tapas:before {
  content: '\ee59';
}
.icon-taxi-alert:before {
  content: '\ee5a';
}
.icon-text-snippet:before {
  content: '\ee5b';
}
.icon-tour:before {
  content: '\ee5c';
}
.icon-tty:before {
  content: '\ee5d';
}
.icon-umbrella:before {
  content: '\ee5e';
}
.icon-upgrade:before {
  content: '\ee5f';
}
.icon-verified:before {
  content: '\ee60';
}
.icon-video-settings:before {
  content: '\ee61';
}
.icon-view-sidebar:before {
  content: '\ee62';
}
.icon-wash:before {
  content: '\ee63';
}
.icon-water-damage:before {
  content: '\ee64';
}
.icon-west:before {
  content: '\ee65';
}
.icon-wheelchair-pickup:before {
  content: '\ee66';
}
.icon-wifi-calling:before {
  content: '\ee67';
}
.icon-wifi-protected-setup:before {
  content: '\ee68';
}
.icon-wine-bar:before {
  content: '\ee69';
}
.icon-wrong-location:before {
  content: '\ee6a';
}
.icon-wysiwyg:before {
  content: '\ee6b';
}
.icon-leaderboard:before {
  content: '\ee6c';
}
.icon-6-ft-apart:before {
  content: '\ee6d';
}
.icon-book-online:before {
  content: '\ee6e';
}
.icon-clean-hands:before {
  content: '\ee6f';
}
.icon-connect-without-contact:before {
  content: '\ee70';
}
.icon-coronavirus:before {
  content: '\ee71';
}
.icon-elderly:before {
  content: '\ee72';
}
.icon-follow-the-signs:before {
  content: '\ee73';
}
.icon-leave-bags-at-home:before {
  content: '\ee74';
}
.icon-masks:before {
  content: '\ee75';
}
.icon-reduce-capacity:before {
  content: '\ee76';
}
.icon-sanitizer:before {
  content: '\ee77';
}
.icon-send-to-mobile:before {
  content: '\ee78';
}
.icon-sick:before {
  content: '\ee79';
}
.icon-add-task:before {
  content: '\ee7a';
}
.icon-contact-page:before {
  content: '\ee7b';
}
.icon-disabled-by-default:before {
  content: '\ee7c';
}
.icon-facebook:before {
  content: '\ee7d';
}
.icon-groups:before {
  content: '\ee7e';
}
.icon-luggage:before {
  content: '\ee7f';
}
.icon-no-backpack:before {
  content: '\ee80';
}
.icon-no-luggage:before {
  content: '\ee81';
}
.icon-outbond:before {
  content: '\ee82';
}
.icon-published-with-changes:before {
  content: '\ee83';
}
.icon-request-page:before {
  content: '\ee84';
}
.icon-stacked-line-chart:before {
  content: '\ee85';
}
.icon-unpublished:before {
  content: '\ee86';
}
.icon-align-horizontal-center:before {
  content: '\ee87';
}
.icon-align-horizontal-left:before {
  content: '\ee88';
}
.icon-align-horizontal-right:before {
  content: '\ee89';
}
.icon-align-vertical-bottom:before {
  content: '\ee8a';
}
.icon-align-vertical-center:before {
  content: '\ee8b';
}
.icon-align-vertical-top:before {
  content: '\ee8c';
}
.icon-horizontal-distribute:before {
  content: '\ee8d';
}
.icon-qr-code-2:before {
  content: '\ee8e';
}
.icon-update-disabled:before {
  content: '\ee8f';
}
.icon-vertical-distribute:before {
  content: '\ee90';
}
.icon-whatsapp:before {
  content: '\ea93';
}
