.UlupinarGallery {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 4rem 0;
  .slick-list {
    margin: 0 -25px;
  }
  &-content {
    position: relative;
    &-title {
      font-size: 22px;
      font-weight: 700;
      padding-bottom: 1rem;
    }
    &-item {
      margin: 0 25px;
      .image {
        width: 100%;
        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }
    &-arrow-next {
      position: absolute;
      right: 35px;
      top: calc(50% + 25px);
      transform: translateY(-50%);
      z-index: 20;
      background-color: transparent;
      border: none;
      .c-icon {
        cursor: pointer;
        color: #decfa6;
        opacity: 1;
        font-size: 3rem;
        font-weight: 500;
        border-radius: 50%;
        text-align: center;
        &::before {
          text-align: center;
        }
      }
    }
    &-arrow-prev {
      position: absolute;
      left: 35px;
      top: calc(50% + 25px);
      transform: translateY(-50%);
      z-index: 20;
      background-color: transparent;
      border: none;
      .c-icon {
        cursor: pointer;
        color: #decfa6;
        opacity: 1;
        font-size: 3rem;
        font-weight: 500;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1500px) {
  .UlupinarGallery {
    padding: 4rem 8rem;
  }
}
@media (max-width: 1300px) {
  .UlupinarGallery {
    padding: 4rem 0;
  }
}
@media (max-width: 850px) {
  .UlupinarGallery {
    padding: 2rem;
    &-item {
      .image {
        img {
          min-height: 275px;
          max-height: 420px;
        }
      }
    }
  }
}
