.ulupinar-video-banner {
  width: 100%;
  height: 100vh;
  background-color: dark-neutral(0);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &-body {
    width: 100%;
    height: 100%;
    position: relative;
    &-image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      transition: all, 0.4s;
      .image {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      &.hidden {
        opacity: 0;
        pointer-events: none;
        transition: all, 0.4s;
      }
    }
    &-video {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      transition: all, 0.4s ease-in-out;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-hidden {
        opacity: 0;
      }
    }
    &-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      height: 75px;
      width: 75px;
      border-radius: 50%;
      background-color: dark-neutral(20);
      display: grid;
      place-content: center;
      animation: grow 1s infinite;
      transition: all .4s ease-in-out;
      .c-icon {
        color: light-neutral(20);
        font-size: 30px;
        line-height: 50px;
        width: 50px;
        height: 50px;
        background-color: #e1d3ae;
        border-radius: 100%;
        text-shadow: rgb(0, 0, 0) 1px 1px 2px !important;
        transition: all .4s ease-in-out;
      }
      &:hover {
        background: #e1d3ae !important;
        .c-icon {
          background: dark-neutral(20) !important;
        }
      }
      &--close {
        top: 200%;
      }
    }
  }
}

@keyframes grow {
  from {
    box-shadow: 0px 0px 1em #FFF;
  }
  to {
    box-shadow: 0px 0px 3em #FFF;
  }
}


@media #{$media-lg} {
  .ulupinar-video-banner {
    margin-bottom: 15px;
    height: 100vh;
    &-body {
      &-video {
        video {
          transform: scale(1.2);
        }
      }
    }
  }
}

@media #{$media-xs} {
  .ulupinar-video-banner {
    margin-bottom: 15px;
    height: 100vh;
    &-body {
      &-video {
        video {
          transform: scale(1.2);
        }
      }
    }
  }
}
