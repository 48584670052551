.ulupinar-header {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  &-logo {
    width: 200px;
  }
  &-navigation-menu {
    ul {
      list-style-type: none;
      display: flex;
      align-items: baseline;
      gap: 1.25rem;
      font-size: 22px;
      padding: 0;
      li {
        margin: 0;
        a {
          color: light-neutral(0);
          font-size: 16px;
          display: block;
          white-space: nowrap;
        }
        &.active {
          a {
            background-color: #e1d3ae;
            color: light-neutral(0) !important;
            padding: 10px;
            border-radius: 6px;
            text-shadow: rgb(0, 0, 0) 2px 2px 4px !important;
          }
        }
      }
    }
  }

  .language-selection {
    position: relative;
    display: inline-flex;
    margin-left: 15px;

    &-current {
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      // padding-right: 18px;
      margin: 0;
      display: block;
      opacity: 0.7;
      color: #fff;
      align-items: center;
      button {
        background: none !important;
        border: none;
        color: light-neutral(0);
        box-shadow: none;
        text-transform: uppercase;
        background-color: #e1d3ae !important;
        text-shadow: rgb(0, 0, 0) 2px 2px 4px !important;
        padding: 5px 10px;
        border-radius: 6px;
        box-sizing: border-box;
        font-weight: bold;
        color: light-neutral(0) !important;
        &:focus,
        &:active {
          border: none !important;
          box-shadow: none !important;
        }
        &:hover {
          background-color: #948661 !important;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 8px;
        font-size: 12px;
        transform: translateY(-50%);
      }
    }

    &-dropdown {
      position: absolute;
      top: 100%;
      left: 50%;
      min-width: 100px;
      transform: translateX(-50%);
      padding-top: 7px;

      &-list {
        position: relative;
        margin: 0;
        padding: 10px 0;
        list-style: none;
        background-color: rgba($color: #080808, $alpha: 0.91);
        border-radius: 8px 8px;

        // &:before {
        //   position: absolute;
        //   content: '';
        //   left: 50%;
        //   bottom: 100%;
        //   width: 0;
        //   height: 0;
        //   border: 10px solid transparent;
        //   border-bottom-color: #fff;
        //   transform: translateX(-50%);
        // }

        li {
          text-align: center;
          cursor: pointer;
          padding: 0px 8px;
          font-size: 14px;
          margin: 0px;

          button {
            background-color: transparent;
            padding: 0px;
            line-height: 20px;
            color: #fff;
            &:not([disabled]):hover,
            &:not([disabled]):focus {
              background-color: transparent;
              color: #fff;
              outline: none;
              box-shadow: inherit;
            }
          }

          &:hover {
            // background-color: #fff;
          }
        }
      }
    }
  }

  .open {
    border-radius: 100%;
    background: dark-neutral(0);
    width: 50px;
    height: 50px;
    border: none;
    .c-icon {
      color: #e1d3ae;
    }
  }
}

.menu-panel {
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 10;
  background-color: #e1d3ae;
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease-in-out;
  padding: 0 15px;
  box-sizing: border-box;
  ul {
    color: dark-neutral(0);
    list-style-type: none;
    gap: 1rem;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      a {
        display: block;
        white-space: nowrap;
        padding: 0 25px;
        line-height: 40px;
        border-radius: 25px;
        font-size: 18px;
        box-sizing: border-box;
      }
      &.active {
        a {
          background-color: dark-neutral(0);
          color: light-neutral(0);
        }
      }
    }
  }
  .language-selection {
    margin: 0;
    &-list {
      padding: 0;
      margin: 0;
      display: flex;
      li {
        list-style-type: none;
        margin: 0;
        button {
          background-color: dark-neutral(0);
          text-transform: uppercase;
          border-radius: 5px;
          width: 40px;
          height: 40px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .close {
    border-radius: 100%;
    background: dark-neutral(0);
    width: 40px;
    height: 40px;
    border: none;
    .c-icon {
      color: #e1d3ae;
    }
  }
  &--active {
    right: 0;
  }
}
@media (max-width: 850px) {
  .menu-panel ul {
    font-size: 2rem;
    padding: 0 0;
    gap: 1rem;
    li:last-child {
      padding: 0;
      border-radius: 0;
    }
  }
}
