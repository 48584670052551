.UlupinarPageHead {
  .thumbnail {
    position: relative;
    background-size: cover !important;
    background-position: center center !important;
    .title {
      margin: 75px 0;
      font-size: 3rem;
      font-weight: 700;
      width: 100%;
    }
  }
}


@media #{$media-sm} {
  .UlupinarPageHead {
    .thumbnail {
      .title {
        position: relative;
        text-align: left;
        transform: none;
        font-size: 25px;
        margin: 15px 0;
      }
    }
  }
}
