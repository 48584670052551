.Capacity-Box {
  position: relative;
  padding: 5rem 0;
  margin-top: 2rem;
  background-color: #e1d3ae;
  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    &-item {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 3rem;
        font-weight: 700;
      }
      .desc {
        font-size: 25px;
      }
    }
  }
}

@media #{$media-sm} {
  .Capacity-Box {
    padding: 25px 0;
    &-inner {
      flex-direction: column;
      &-item {
        margin: 15px 0;
        .title {
          font-size: 20px;
        }
        .desc {
          font-size: 14px;
        }
      }
    }
  }
}
