.UlupinarSlider {
  position: relative;
  border-top: 1px solid;
  padding: 4rem 0;
  &-desc {
    font-size: 30px;
    padding: 15px 0;
  }
  &-head {
    // display: flex;
    // justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: 700;
    }
    .more {
      display: flex;
      align-items: center;
    }
  }
  .slick-list {
    margin: 0 -25px;
    .slick-slide {
      > div {
        margin: 0 25px;
      }
    }
  }
  .in-slider {
    position: relative;
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background-color: dark-neutral(0);
      width: 50px;
      height: 50px;
      color: #decfa6;
      &.arrow-prev {
        left: -35px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &.arrow-next {
        right: -35px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
  &-slider {
    background-color: transparent;
    border: none;
    cursor: pointer;
    &-item {
      width: 100%;
      background-color: #decfa6;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: none;
      cursor: pointer;
      // &--active {
      //   background-color: #decfa6;
      // }
      .image {
        width: 130px;
      }
      &-text {
        color: dark-neutral(0);
      }
    }
  }
}
.UlupinarGlobal {
  width: 100%;
  display: flex;
  &-left {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    &-item {
      display: flex;
      align-items: center;
      padding: 5px;
      gap: 5px;
      width: 40%;
      .flag {
        width: 40px;
      }
      .name {
        font-size: 19px;
        width: calc(100% - 50px);
      }
    }
  }
  &-right {
    width: 50%;
    .thumbnail {
      width: 100%;
      height: 100%;
    }
  }
}
.UlupinarJourney {
  &-slider {
    position: relative;
    &-item {
      background-color: #decfa6;
      min-width: 250px;
      max-width: 250px;
      height: 170px;
      display: flex;
      align-items: center;
      .in {
        width: 100%;
        text-align: center;
        .year {
          font-size: 2rem;
          font-weight: 700;
        }
        .description {
          p {
            font-size: 14px;
          }
        }
      }

    }
    .description {
      width: 100%;
      text-align: center;
    }
    &::before {
      content: '';
      width: 100%;
      height: 5px;
      position: absolute;
      left: -100%;
      top: 9rem;
      background-color: #decfa6;
      z-index: -1;
    }
  }
}

.Ulupinar-Prefers {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-item {
    background-color: #f9f9f9;
    padding: 1rem;
    font-size: 21px;
  }
}
@media (max-width: 1500px) {
  .UlupinarSlider {
    padding: 4rem 0;
  }
}
@media (max-width: 1300px) {
  .UlupinarSlider {
    &-desc {
      font-size: 21px;
    }
  }
}
@media (max-width: 850px) {
  .UlupinarSlider {
    padding: 4rem 1rem;
    &-head {
      font-size: 18px;
    }
    &-desc {
      font-size: 14px;
    }
    &-slider {
      &-item {
        max-width: 1000px;
      }
    }
  }
  .Ulupinar-Prefers {
    &-item {
      font-size: 16px;
    }
  }
  .UlupinarGlobal {
    flex-direction: column;
    gap: 2rem;
    &-left {
      width: 100%;
      &-item {
          .name {
            font-size: 13px;
          }
      }
    }
    &-right {
      width: 100%;
    }
  }


  .UlupinarJourney {
    &-slider {
      &-item {
        max-width: 1000px;
      }
      .description {
        font-size: 20px;
      }
    }
  }
}
