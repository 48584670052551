.UlupinarBanner {
  position: relative;
  box-sizing: border-box;
  &-inner {
    position: relative;
    min-height: 500px;
    box-sizing: border-box;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgb(222, 207, 166);
      background: linear-gradient(90deg, rgb(222, 207, 166) 40%, rgba(222, 207, 166, 0) 100%);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    &::after {
      content: "";
      width: 40%;
      height: 100%;
      background-image: url('https://minio.wapbukafamedya.com/storage/Root/2ea0-ulupinar-tekstil/icon-after.png');
      background-repeat: no-repeat;
      background-position: left center;
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    &-content {
      position: relative;
      z-index: 3;
      &-text {
        color: light-neutral(0);
        margin-bottom: 2rem;
        font-weight: 700;
        text-shadow: 2px 2px 4px dark-neutral(0);
        font-size: 18px;
        h3 {
          font-size: 50px;
        }
      }
      &-button {
        background-color: light-neutral(0);
        padding: 10px;
        border-radius: 4px;
        font-weight: 500;
        display: inline-block;
      }
    }
  }
}

@media #{$media-sm} {
  .UlupinarBanner {
    &-inner {
      flex-direction: column;
      min-height: 300px;
      &-content {
        &-text {
          h3 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

