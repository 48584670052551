.ulupinar-works {
  margin: 15px 0;
  &-info {
    display: flex;
    flex-direction: column;
    &-title {
      margin: 0 0 15px 0 !important;
      display: block;
      width: 100%;
    }
    &-desc {
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  &-image {
    width: 100%;
  }
}
